import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';
import { SeoService } from '../../services/seo/seo-service.service';
import { Destination } from '../../models/Destination';
import { Router, NavigationExtras } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Address } from '../../models/Address';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import Typewriter from 'typewriter-effect/dist/core';
import { TranslateService } from '@ngx-translate/core';


interface SearchParams {
  daterange: {
    start: string;
    end: string;
  };
  cities: Address[];
  adults: number;
  children: number;
  infants: number;
  longitude: number;
  latitude: number;
  place_id: string;
  place_name: string;
  address_details: Address;
}

interface IntroSlide {
  showVideo?: boolean;
  videoURL?: string;
  showImage?: boolean;
  imageURL?: string;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomePageComponent implements OnInit, OnDestroy {

  public popularDestinations: Destination[];

  public isMobile = false;
  private isMobileSubscription: Subscription;

  private guestsFilterSubscription: Subscription;
  private addressAutoCompleteFilterSubscription: Subscription;
  private LanguageSubscription: Subscription;
  private environmentFetchedSubscription: Subscription;

  public filters: SearchParams = {
    daterange: null,
    cities: [],
    adults: 1,
    children: 0,
    infants: 0,
    longitude: null,
    latitude: null,
    place_id: null,
    place_name: null,
    address_details: null
  };

  public maxAdults = environment.params.filterParams.maxAdults;
  public minAdults = environment.params.filterParams.minAdults;

  public maxChildren = environment.params.filterParams.maxChildren;
  public minChildren = environment.params.filterParams.minChildren;

  public maxInfants = environment.params.filterParams.maxInfants;
  public minInfants = environment.params.filterParams.minInfants;


  public datepickerMinDate = new Date();
  public date_range: string[] | Date[];

  public welcomeCarouselSettings = {

  };

  public welcomeSlides = [
    { image: 'assets/img/home-welcome/25.webp' },
    { image: 'assets/img/home-welcome/3.webp' },
    { image: 'assets/img/home-welcome/10.webp' },
    { image: 'assets/img/home-welcome/23.webp' },
    { image: 'assets/img/home-welcome/4.webp' },
  ];


  public carouselSettings = {
    itemsPerSlide: 5,
    singleSlideOffset: true,
  };


  public introSlide: IntroSlide = {
    showVideo: false,
    videoURL: 'assets/demo/homepage_video.mp4',
    showImage: true,
    imageURL: 'assets/backgrounds/Tourmie.jpg'
  };


  constructor(
    private seo: SeoService,
    public utilsService: UtilsService,
    private router: Router,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef,
    private pubSub: NgxPubSubService,
    private translate: TranslateService
  ) {

    this.isMobile = this.utilsService.isMobile;

    this.date_range = [
      // moment().toDate(),
      // moment().add(environment.params.minBookingDays, 'days').toDate()
    ];

    this.filters.daterange = {
      start: this.date_range?.length > 0 ? moment(this.date_range[0]).format('YYYY-MM-DD') : null,
      end: this.date_range?.length > 1 ? moment(this.date_range[1]).format('YYYY-MM-DD') : null
    };

  }



  ngOnInit(): void {

    if (this.utilsService.isBrowser) {

      this.seo.updatePageMetadata({ page: 'home' });

      this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

      this.initializeTypewriter();


      this.environmentFetchedSubscription = this.pubSub.subscribe('environmentFetched', () => {
        this.initializeTypewriter();
      });

      this.guestsFilterSubscription = this.pubSub.subscribe('updateGuestsFilter', (guestsFilter) => {
        this.filters.adults = guestsFilter.adults;
        this.filters.children = guestsFilter.children;
        this.filters.infants = guestsFilter.infants;
      });

      this.addressAutoCompleteFilterSubscription = this.pubSub.subscribe('updateAddressFilter', (addressFilter) => {

        this.filters.longitude = addressFilter.longitude;
        this.filters.latitude = addressFilter.latitude;
        this.filters.place_id = addressFilter.place_id;
        this.filters.place_name = addressFilter.location;
        this.filters.address_details = addressFilter.address_details;


        this.filters.cities = []; // clear array
        this.filters.cities.push(addressFilter.location);

      });

      this.LanguageSubscription = this.pubSub.subscribe('changeLanguage', () => {
        this.initializeTypewriter();
      });


    }

    //  this.getPopularDestinations();
  }



  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

    if (this.guestsFilterSubscription)
      this.guestsFilterSubscription.unsubscribe();

    if (this.addressAutoCompleteFilterSubscription)
      this.addressAutoCompleteFilterSubscription.unsubscribe();

    if (this.LanguageSubscription)
      this.LanguageSubscription.unsubscribe();

    if (this.environmentFetchedSubscription)
      this.environmentFetchedSubscription.unsubscribe();
  }



  async onDaterangeChange(): Promise<void> {

    const difference = moment(this.date_range[1]).diff(this.date_range[0], 'days');

    if (difference < environment.params.minBookingDays) {

      const startDate = moment(this.date_range[0]).toDate();
      const endDate = moment(this.date_range[0]).add(environment.params.minBookingDays, 'days').toDate();

      this.date_range = [startDate, endDate];

      this.utilsService.showToast((await this.translate.get('GENERIC.ALERTS.MINIMUM_DAYS').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title), null, 'warning', 3000);
    }

    if (difference > environment.params.maxBookingDays) {

      const startDate = moment(this.date_range[0]).toDate();
      const endDate = moment(this.date_range[0]).add(environment.params.maxBookingDays, 'days').toDate();

      this.date_range = [startDate, endDate];

      this.utilsService.showToast((await this.translate.get('GENERIC.ALERTS.MAXIMUM_DAYS').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title), null, 'warning', 3000);
    }

    this.filters.daterange = {
      start: moment(this.date_range[0]).format('YYYY-MM-DD'),
      end: moment(this.date_range[1]).format('YYYY-MM-DD')
    };

  }



  async getPopularDestinations(): Promise<void> {

    this.popularDestinations = [];

    try {
      const results: any = await this.http.get(`${environment.params.host}/api/stay/destinations?popular=1`, {}).toPromise();

      this.popularDestinations = results;

    } catch (error) {

      if (!environment.production)
        console.log(error);

    }
  }



  navigateToDestination(city: 'string'): void {

    try {
      const params: NavigationExtras = {
        queryParams: {
          city: city,
        }
      };

      this.router.navigate(['/properties'], params);

    } catch (error) {

      if (!environment.production)
        console.log(error);


    }
  }



  async submitForm(): Promise<void> {

    try {

      const params: NavigationExtras = {
        queryParams: {
          location: this.filters.cities.toString(),
          latitude: this.filters.latitude,
          longitude: this.filters.longitude,
          adults: this.filters.adults,
          children: this.filters.children,
          infants: this.filters.infants,
          start_date: this.filters.daterange.start,
          end_date: this.filters.daterange.end,
        }
      };


      this.router.navigate(['/properties'], params);

    } catch (error) {

      if (!environment.production)
        console.log(error);


    }

  }



  async initializeTypewriter(): Promise<void> { // typewriting animation for home page 1st section (intro)


    // set the settings per custom domain here - START
    const visible_texts = {
      text1: true,
      text2: true,
      text3: true,
      text4: true,
      text5: true,
      text6: true,
      text7: true,
    };


    visible_texts.text1 = this.utilsService.customDomainSettings.visible_elements.home_typewritter_text1;
    visible_texts.text2 = this.utilsService.customDomainSettings.visible_elements.home_typewritter_text2;
    visible_texts.text3 = this.utilsService.customDomainSettings.visible_elements.home_typewritter_text3;
    visible_texts.text4 = this.utilsService.customDomainSettings.visible_elements.home_typewritter_text4;
    visible_texts.text5 = this.utilsService.customDomainSettings.visible_elements.home_typewritter_text5;
    visible_texts.text6 = this.utilsService.customDomainSettings.visible_elements.home_typewritter_text6;
    visible_texts.text7 = this.utilsService.customDomainSettings.visible_elements.home_typewritter_text7;

    // set the settings per custom domain here - END





    const element = document.getElementById('typewriterText');

    const typewriter = new Typewriter(element, { loop: true });



    if (visible_texts?.text1)
      typewriter
        .typeString((await this.translate.get('PAGES.HOME.WELCOME_SECTION.ROTATING_TEXT_1').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title))
        .pauseFor(1000)
        .deleteAll();

    if (visible_texts?.text2)
      typewriter
        .typeString((await this.translate.get('PAGES.HOME.WELCOME_SECTION.ROTATING_TEXT_2').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title))
        .pauseFor(1000)
        .deleteAll();

    if (visible_texts?.text3)
      typewriter
        .typeString((await this.translate.get('PAGES.HOME.WELCOME_SECTION.ROTATING_TEXT_3').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title))
        .pauseFor(1000)
        .deleteAll();


    if (visible_texts?.text4)
      typewriter
        .typeString((await this.translate.get('PAGES.HOME.WELCOME_SECTION.ROTATING_TEXT_4').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title))
        .pauseFor(1000)
        .deleteAll();


    if (visible_texts?.text5)
      typewriter
        .typeString((await this.translate.get('PAGES.HOME.WELCOME_SECTION.ROTATING_TEXT_5').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title))
        .pauseFor(1000)
        .deleteAll();



    if (visible_texts?.text6)
      typewriter
        .typeString((await this.translate.get('PAGES.HOME.WELCOME_SECTION.ROTATING_TEXT_6').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title))
        .pauseFor(1000)
        .deleteAll();


    if (visible_texts?.text7)
      typewriter
        .typeString((await this.translate.get('PAGES.HOME.WELCOME_SECTION.ROTATING_TEXT_7').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title))
        .pauseFor(1000)
        .deleteAll();



    typewriter.start();

  }




}



export class Country {
    name: string;
    code: string;
    vatPrefix: string;

    constructor() {
        this.name = null;
        this.code = null;
        this.vatPrefix = null;
    }
}

<div class="container bg-tourmie-darkblue w-100 max-w-100" [ngClass]="{'px-3': isMobile}"
    *ngIf="utilsService.customDomainSettings?.visible_elements?.footer_newsletter" [hidden]="!utilsService.showContent">

    <div class="row">

        <div class="col-12">

            <app-mail-subscription-form [labelFontSize]="'16'" [style]="'footer'"></app-mail-subscription-form>

        </div>

    </div>

</div>


<footer class="bg-tourmie-darkblue" [ngClass]="{
        'px-3': isMobile, 'pt-5': utilsService.customDomainSettings?.visible_elements?.footer_newsletter,
        'pt-3': utilsService.customDomainSettings?.visible_elements?.tourmie_newsletter
    }" [hidden]="!utilsService.showContent">

    <div class="container" [ngClass]="{'pt-5': !utilsService.customDomainSettings?.visible_elements?.footer_newsletter}"
        [ngClass]="{'pt-6': !utilsService.customDomainSettings?.visible_elements?.footer_newsletter, 'pb-6': (!utilsService.customDomainSettings?.visible_elements?.footer_footer_copyright || !utilsService.customDomainSettings?.visible_elements?.footer_footer_app_version)}">

        <div class="row">

            <!-- Column 1: Logo & social -->
            <div class="col-sm-12 col-md-4 col-lg-4" [ngClass]="{'py-3': isMobile}">

                <div class="row">

                    <div class="col-12" [ngClass]="!isMobile ? 'text-center' : ''">

                        <img [src]="utilsService.customDomainSettings?.logo?.light || '/assets/img/logo/tourmie-stay-logo-white.png'" alt="TourmieStay"
                            title="TourmieStay" width="140" height="auto">

                        <p class="text-white font-size-14 mt-2 mb-0 pl-1">
                            {{ 'GENERIC.FOOTER.LOGO_SUBTEXT' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                            'Tourmie Stay'}}
                        </p>

                    </div>

                </div>


                <div class="row mt-2" *ngIf="(utilsService?.customDomainSettings?.social?.facebook?.visible && utilsService?.customDomainSettings?.social?.facebook?.url)
                || (utilsService?.customDomainSettings?.social?.instagram?.visible && utilsService?.customDomainSettings?.social?.instagram?.url)
                || (utilsService?.customDomainSettings?.social?.linkedin?.visible && utilsService?.customDomainSettings?.social?.linkedin?.url)">

                    <div class="col-12" [ngClass]="!isMobile ? 'text-center' : ''">

                        <a *ngIf="utilsService?.customDomainSettings?.social?.facebook?.visible" [href]="utilsService.customDomainSettings.social.facebook.url"
                            target="_blank" [ngClass]="isMobile ? 'font-size-30 pl-1 mr-3' : 'font-size-20 pl-1 mr-3'"
                            [gaEvent]="{ title: 'click_stay_social_icon_facebook' }" title="Folow us on Facebook">
                            <i class="fab fa-facebook-square"></i>
                        </a>

                        <a *ngIf="utilsService?.customDomainSettings?.social?.linkedin?.visible" [href]="utilsService.customDomainSettings.social.linkedin.url"
                            target="_blank" [ngClass]="isMobile ? 'font-size-30 mr-3' : 'font-size-20 mr-3'"
                            [gaEvent]="{ title: 'click_stay_social_icon_linkedin' }" title="Follow us on Linkedin">
                            <i class="fab fa-linkedin"></i>
                        </a>

                        <a *ngIf="utilsService?.customDomainSettings?.social?.instagram?.visible"
                            [href]="utilsService.customDomainSettings.social.instagram.url" target="_blank"
                            [ngClass]="isMobile ? 'font-size-30 mr-3' : 'font-size-20 mr-3'" [gaEvent]="{ title: 'click_stay_social_icon_instagram' }"
                            title="Follow us on Instagram">
                            <i class="fab fa-instagram"></i>
                        </a>

                    </div>

                </div>

            </div>







            <!-- Column 2: Landlords -->
            <div class="col-sm-12 col-md-2 col-lg-2" [ngClass]="{'py-3': isMobile}">

                <div class="row">

                    <div class="col-12">

                        <h5 class="text-white">{{'GENERIC.LABELS.LANDLORD_MULTIPLE' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}</h5>

                        <ul class="list-unstyled text-small">
                            <li>
                                <a class="text-white" [routerLink]="[ '/listyourproperty']">
                                    {{'GENERIC.FOOTER.LANDLORDS_LIST_PROPERTY_LABEL' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </a>
                            </li>

                            <li>
                                <a class="text-white" [routerLink]="[ '/listyourproperty']" fragment="how-it-works">
                                    {{'GENERIC.FOOTER.LANDLORDS_HOW_IT_WORKS_LABEL' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </a>
                            </li>

                            <li>
                                <a class="text-white" [routerLink]="['/demo-request']"
                                    [gaEvent]="{ title: 'click_request_a_demo', data: { position: 'footer' } }">
                                    {{'GENERIC.FOOTER.LANDLORDS_REQUEST_DEMO_LABEL' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </a>
                            </li>

                        </ul>

                    </div>

                </div>

            </div>



            <!-- Column 3: Tenants -->
            <div class="col-sm-12 col-md-2 col-lg-2" [ngClass]="{'py-3': isMobile}">

                <div class="row">

                    <div class="col-12">

                        <h5 class="text-white">{{'GENERIC.LABELS.TENANT_MULTIPLE' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}</h5>

                        <ul class="list-unstyled text-small">
                            <li>
                                <a class="text-white" [routerLink]="[ '/properties']">
                                    {{'GENERIC.FOOTER.TENANTS_FIND_A_HOME_LABEL' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </a>
                            </li>

                            <li>
                                <a class="text-white" [routerLink]="[ '/registration']">
                                    {{'GENERIC.FOOTER.TENANTS_CREATE_ACCOUNT_LABEL' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </a>
                            </li>
                        </ul>

                    </div>

                </div>

            </div>

            <!-- Column 4: Company -->
            <div class="col-sm-12 col-md-2 col-lg-2" [ngClass]="{'py-3': isMobile}">

                <h5 class="text-white">{{'GENERIC.FOOTER.COMPANY_LABEL' | translate | replaceTourmieStay:
                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}</h5>

                <ul class="list-unstyled text-small">
                    <li>
                        <a class="text-white" [href]="utilsService.customDomainSettings.footer_links.about_us" target="_blank">
                            {{'GENERIC.FOOTER.ABOUT_US_LABEL' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                            'Tourmie Stay'}}
                        </a>
                    </li>

                    <li>
                        <a class="text-white" [routerLink]="[ '/privacy-policy']" target="_blank">
                            {{'GENERIC.FOOTER.PRIVACY_POLICY_LABEL' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </a>
                    </li>

                    <li>
                        <a class="text-white" [routerLink]="['/terms-of-service']" target="_blank">
                            {{'GENERIC.FOOTER.TERMS_CONDITIONS_LABEL' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </a>
                    </li>
                </ul>

            </div>

            <!-- Column 5: Support -->
            <div class="col-sm-12 col-md-2 col-lg-2" [ngClass]="{'py-3': isMobile}">

                <h5 class="text-white">{{'GENERIC.FOOTER.SUPPORT_LABEL' | translate | replaceTourmieStay:
                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}</h5>


                <ul class="list-unstyled text-small">
                    <li *ngIf="utilsService.hostIsTourmie">
                        <a class="text-white" [routerLink]="[ '/faq']">
                            {{'GENERIC.FOOTER.SUPPORT_FAQ_LABEL' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title
                            || 'Tourmie Stay'}}
                        </a>
                    </li>

                    <li>
                        <a class="text-white" [href]="utilsService.customDomainSettings.footer_links.contact">
                            {{'GENERIC.FOOTER.SUPPORT_CONTACT_LABEL' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </a>
                    </li>


                </ul>

            </div>

        </div>



        <div class="row py-3" *ngIf="utilsService.customDomainSettings?.visible_elements?.footer_stama_logo">

            <div class="col-sm-12 " [ngClass]="{'py-3': isMobile, 'text-center': !isMobile}">

                <p class="mb-2 text-white">
                    {{ 'GENERIC.FOOTER.STAMA_LOGO_HEADING' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                    'Tourmie Stay'}}
                </p>
                <a href="https://www.stamagreece.gr/en" target="_blank" rel="noopener noreferrer">
                    <img src="assets/img/footer/Stama-white-logo-min.png" class="stama-logo" alt="Stama">
                </a>

            </div>

        </div>



        <div class="row py-3 text-center"
            *ngIf="utilsService.customDomainSettings?.visible_elements?.footer_app_version || utilsService.customDomainSettings?.visible_elements?.footer_copyright">

            <div class="col-12 py-2">

                <div class="text-white">

                    <small class="d-block" *ngIf="utilsService.customDomainSettings?.visible_elements?.footer_app_version">
                        Version {{environment.params.appVersion}}
                    </small>
                    <small *ngIf="utilsService.customDomainSettings?.visible_elements?.footer_copyright">
                        Copyright © {{today | date: 'yyyy'}} <a href="https://www.tourmie.com" target="_blank" class="text-tourmie-primary">{{
                            utilsService.customDomainSettings.app_title }}</a>
                    </small>

                </div>

            </div>

        </div>

    </div>

</footer>
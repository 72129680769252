import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Customer } from '../../models';
import { environment } from '../../../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { isEmpty } from 'lodash';
import { isPlatformBrowser } from '@angular/common';

declare const gtag: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  public gtag: any = null;

  private customer: Customer = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pubSub: NgxPubSubService,
    private storageService: StorageService,
    public analytics: AngularFireAnalytics,
  ) {

    if (isPlatformBrowser(this.platformId))
      this.gtag = gtag;

    this.customer = this.storageService.getItem('customer');

    this.pubSub.subscribe('customerDataUpdated', async (customer: Customer) => {
      this.customer = customer;
    });

  }

  startTracking() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild)
            route = route.firstChild;

          return route;
        }),
        mergeMap((route) => route.data))
      .subscribe((data) => {

        if (isPlatformBrowser(this.platformId))
          gtag('config', environment.params.gtagTrackingID, {
            page_title: data && data.analyticsScreen ? data.analyticsScreen : environment.params.appTitle,
            page_path: this.router.url,
            page_location: environment.params.host + this.router.url
          });

      });
  }


  public setUserID(userIdStr: string): void {

    if (!environment.production) return;

    if (isPlatformBrowser(this.platformId)) {
      gtag('config', environment.params.gtagTrackingID, { user_id: userIdStr });
      gtag('set', { user_id: userIdStr });
    }

  }



  public sendScreenName(screenName: string): void {

    if (!environment.production) return;

    if (isPlatformBrowser(this.platformId))
      gtag('event', 'screen_view', { screen_name: screenName, app_name: 'stay.tourmie.com', app_version: environment.params.appVersion });
  }



  public sendPageView(pagePath: string): void {

    if (!environment.production) return;

    if (isPlatformBrowser(this.platformId))
      gtag('config', environment.params.gtagTrackingID, { page_path: pagePath, customer_id: this.customer?.customer_id || null });
  }



  public sendEvent(eventTitle: string, eventData?: any): void {

    if (!environment.production) return;

    if (!eventTitle)
      return;

    if (eventData && typeof eventData === 'object' && !isEmpty(eventData))
      eventData = {
        ...eventData,
        app_name: 'stay.tourmie.com',
        app_version: environment.params.appVersion,
        customer_id: this.customer?.customer_id || null
      };

    // if (!environment.production)
    // console.log({ title: eventTitle, data: eventData || null });

    if (isPlatformBrowser(this.platformId))
      gtag('event', eventTitle, eventData || {});

  }



  public sendErrorEvent(description: string): void {

    if (!environment.production) return;

    if (isPlatformBrowser(this.platformId))
      gtag('event', 'exception', description);
  }



  public sendLoginEvent() {

    if (!environment.production) return;

    if (isPlatformBrowser(this.platformId))
      gtag('event', 'login', { method: 'email-password' });
  }



  public sendSignupEvent() {

    if (!environment.production) return;

    if (isPlatformBrowser(this.platformId))
      gtag('event', 'sign_up', { method: 'email-password' });
  }



  // Allows you to set values that persist across all the subsequent gtag() calls on the page.
  public setAttributes(attributes: any) {

    if (!environment.production) return;

    // example: gtag('set', {'country': 'US', 'currency': 'USD'});

    if (isPlatformBrowser(this.platformId))
      gtag('set', attributes);
  }


}

import { Customer } from '../../models';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/messaging';


@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    public firebase = firebase;

    constructor(
        private http: HttpClient,
        private firebaseMessaging: AngularFireMessaging,
    ) {

    }

    async updateFcmToken(customer?: Customer): Promise<void> {

        try {

            if (!this.firebase.messaging.isSupported())
                return Promise.resolve();

            const notificationsPermissionResult = await this.firebaseMessaging.requestPermission.toPromise();

            const fcmToken = await this.firebaseMessaging.getToken.toPromise();

            // tslint:disable-next-line:curly
            if (fcmToken && customer?.customer_id) {
                const updateFcmTokenResponse = await this.http.post(`${environment.params.host}/api/stay/update-fcm-token`, {
                    fcm_token: fcmToken,
                    device_hash: customer?.fingerprint?.hash || null
                }).toPromise();

            }

            return Promise.resolve();

        } catch (error) {

            if (!environment.production)
                console.log(error);

            return Promise.resolve();

        }

    }

}


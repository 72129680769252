import { environment } from '../../environments/environment';

export class StayEnvironmentSettings {

    domain?: string;
    app_title?: string;
    in_beta_version?: boolean;

    logo?: {
        dark: string;
        light: string;
    };

    favicon?: { url: string; };

    colors?: {
        primary: string;
        secondary: string;
        primary_text?: string;
        secondary_text?: string;
    };

    visible_elements?: {
        home_typewritter_text1?: boolean;
        home_typewritter_text2?: boolean;
        home_typewritter_text3?: boolean;
        home_typewritter_text4?: boolean;
        home_typewritter_text5?: boolean;
        home_typewritter_text6?: boolean;
        home_typewritter_text7?: boolean;
        home_categories_nomads?: boolean;
        home_categories_professionals?: boolean;
        home_categories_students?: boolean;
        home_categories_executives?: boolean;
        home_categories_families?: boolean;
        why_rent_with_tourmie_stay_section__fully_furnished?: boolean;
        why_rent_with_tourmie_stay_section__high_speed_wifi?: boolean;
        why_rent_with_tourmie_stay_section__online_payments?: boolean;
        why_rent_with_tourmie_stay_section__no_hidden_extra_fees?: boolean;
        why_rent_with_tourmie_stay_section__digital_nomads_friendly?: boolean;
        footer_stama_logo?: boolean;
        footer_newsletter?: boolean;
        footer_copyright?: boolean;
        footer_app_version?: boolean;
        list_your_property__square_meters_cycle?: boolean;
        list_your_property__newly_built_cycle?: boolean;
        circles_white_icon?: boolean;
    };



    images?: {
        home?: {
            section_how_it_works?: {
                item_1: string;
                item_2: string;
                item_3: string;
                item_4: string;
            }
        };
        list_your_property?: {
            section_why_rent?: {
                item_1: string;
                item_2: string;
                item_3: string;
                item_4: string;
            };
            section_how_it_works?: {
                item_2: string;
            }
        };
    };

    footer_links?: {
        about_us?: string;
        contact?: string;
    };

    social?: {
        facebook: { visible?: boolean; url?: string; };
        instagram: { visible?: boolean; url?: string; };
        twitter: { visible?: boolean; url?: string; };
        linkedin: { visible?: boolean; url?: string; };
        youtube: { visible?: boolean; url?: string; };
        tiktok: { visible?: boolean; url?: string; };
        whatsapp: { visible?: boolean; url?: string; };
    };

    supported_languages?: string[];

    seo?: {
        subtitle?: { en?: string; el?: string; };
        description?: { en?: string; el?: string; };
        keywords?: { en?: string; el?: string; };
        robots?: string;
        'og:title'?: { en?: string; el?: string; };
        'og:description'?: { en?: string; el?: string; };
        'og:locale'?: string;
        'og:locale:alternate'?: string;
        'og:type'?: string;
        'og:url'?: { en?: string; el?: string; };
        'og:site_name'?: { en?: string; el?: string; };
        'og:image'?: { en?: string; el?: string; };
        'twitter:card'?: string;
        'twitter:description'?: { en?: string; el?: string; };
        'twitter:title'?: { en?: string; el?: string; };
    };

    accommodation?: {
        min_days: number;
    };

    // db record meta
    meta?: {
        id?: number;
        uuid?: string;
        domain?: string;
        updated_at?: string;
        created_at?: string;
    };


    constructor(props?: StayEnvironmentSettings) {

        this.domain = props?.domain || environment.params.host_frontend_domain;
        this.in_beta_version = typeof props?.in_beta_version !== 'undefined' ? (props?.in_beta_version ? true : false) : environment.params.showBetaNotification;
        this.app_title = props?.app_title || environment.params.appTitle;

        this.logo = props?.logo || null;
        // {
        //     dark: '/assets/img/logo/tourmie-stay-logo-dark-blue.png',
        //     light: '/assets/img/logo/tourmie-stay-logo-white.png'
        // };

        this.favicon = props?.favicon || null;

        this.colors = props?.colors || null; // { primary: '#faaf40', secondary: '#27a9e0', primary_text: '#212529', secondary_text: '#ffffff', };

        this.visible_elements = props?.visible_elements || {
            home_typewritter_text1: true,
            home_typewritter_text2: true,
            home_typewritter_text3: true,
            home_typewritter_text4: true,
            home_typewritter_text5: true,
            home_typewritter_text6: true,
            home_typewritter_text7: true,
            home_categories_nomads: true,
            home_categories_professionals: true,
            home_categories_students: true,
            home_categories_executives: true,
            home_categories_families: true,
            why_rent_with_tourmie_stay_section__fully_furnished: true,
            why_rent_with_tourmie_stay_section__high_speed_wifi: true,
            why_rent_with_tourmie_stay_section__online_payments: true,
            why_rent_with_tourmie_stay_section__no_hidden_extra_fees: true,
            why_rent_with_tourmie_stay_section__digital_nomads_friendly: true,
            footer_stama_logo: true,
            footer_newsletter: true,
            footer_copyright: true,
            footer_app_version: false,
            list_your_property__square_meters_cycle: true,
            list_your_property__newly_built_cycle: true,
            circles_white_icon: false,
        };


        this.images = props?.images || {
            home: {
                section_how_it_works: {
                    item_1: '/assets/img/home-how-it-works/how-it-works-1.webp',
                    item_2: '/assets/img/home-how-it-works/how-it-works-2.webp',
                    item_3: '/assets/img/home-how-it-works/how-it-works-3.webp',
                    item_4: '/assets/img/home-how-it-works/how-it-works-4.webp',
                }
            },
            list_your_property: {
                section_why_rent: {
                    item_1: '/assets/img/list-why-tourmiestay/why_rent_en.webp',
                    item_2: '/assets/img/list-why-tourmiestay/save_time_en.webp',
                    item_3: '/assets/img/list-why-tourmiestay/secure_payments_en.webp',
                    item_4: '/assets/img/list-why-tourmiestay/increase_revenue_en.webp',
                },
                section_how_it_works: {
                    item_2: '/assets/img/list-how-it-works/02.webp'
                }
            },
        };

        this.footer_links = props?.footer_links || {
            about_us: 'https://tourmie.com/about-tourmie',
            contact: '/contact'
        };

        this.social = props?.social || {
            facebook: { visible: false, url: null },
            instagram: { visible: false, url: null },
            twitter: { visible: false, url: null },
            linkedin: { visible: false, url: null },
            youtube: { visible: false, url: null },
            tiktok: { visible: false, url: null },
            whatsapp: { visible: false, url: null },
        };

        this.supported_languages = props?.supported_languages || ['en', 'el'];

        this.seo = props?.seo || {
            subtitle: {
                en: 'Fully furnished apartments for mid-term rent',
                el: 'Fully furnished apartments for mid-term rent',
            },
            description: {
                en: `${environment.params.appTitle} is an online booking platform for mid-term stays of fully furnished apartments and houses, rented from one month up to one year.`,
                el: `${environment.params.appTitle} is an online booking platform for mid-term stays of fully furnished apartments and houses, rented from one month up to one year.`,
            },
            keywords: {
                en: 'rentals, furnished apartments, accommodation, home, apartments, houses, apartment, house, properties, rental properties, rental property, rental, homes, housing, mid-term stays, stays, property, month-to-month rentals, monthly rentals, extended stays, mid-term stay, flexible lease, accommodation for a few months, monthly stays, villas, flats, studios, booking, deals, special offers, move-in ready, off-campus housing, digital nomads, homes for digital nomads, apartments for rent, apartments for rent in Greece, Greece, Heraklion, Athens, accommodation in Greece, fully furnished properties, rental properties in greece, properties for rent, apartments for students',
                el: 'ενοικιάσεις σπιτιών, σπίτια προς ενοικίαση, διαμέρισμα για ενοικίαση, ενοικίαση σπιτιού, διαμέρισμα, διαμονή, διαμερίσματα, ενοικιαζόμενα σπίτια, φοιτητικά σπίτια, ενοικιάσεις κατοικιών, καταλύματα, επιπλωμένα σπίτια, ψάχνω σπίτι, ιδιοκτήτες ακινήτων, αγγελίες σπιτιών, γκαρσονιέρες, στούντιο, ειδικές προσφορές, ενοικίαση με τον μήνα, σπίτια, εύρεση σπιτιού, ενοικιασεις σπιτιων, μεσοπρόθεσμη ενοικίαση, ενοικιασεις κατοικιων, σπιτια προς ενοικιαση, καταλυματα, κρατήσεις, ακίνητα, ευκαιρίες, μεσίτες, αγγελίες σπιτιών, γκαρσονιέρα, μονοκατοικία, δυάρι, τριάρι, ελλάδα, ηράκλειο, αθήνα'
            },
            robots: 'index, follow',
            'og:title': {
                en: environment.params.appTitle,
                el: environment.params.appTitle,
            },
            'og:description': {
                en: environment.params.appTitle,
                el: environment.params.appTitle,
            },
            'og:locale': 'en_US',
            'og:locale:alternate': 'el_GR',
            'og:type': 'website',
            'og:url': {
                en: environment.params.host_frontend,
                el: environment.params.host_frontend,
            },
            'og:site_name': {
                en: environment.params.appTitle,
                el: environment.params.appTitle,
            },
            'og:image': {
                en: `${environment.params.host_frontend}/assets/img/seo/og-tourmie-stay.png`,
                el: `${environment.params.host_frontend}/assets/img/seo/og-tourmie-stay.png`,
            },
            'twitter:card': 'summary_large_image',
            'twitter:description': {
                en: `${environment.params.appTitle} is an online booking platform for mid-term stays of fully furnished apartments and houses, rented from one month up to one year.`,
                el: `${environment.params.appTitle} is an online booking platform for mid-term stays of fully furnished apartments and houses, rented from one month up to one year.`,
            },
            'twitter:title': {
                en: environment.params.appTitle,
                el: environment.params.appTitle,
            },
        };

        this.accommodation = props?.accommodation || {
            min_days: 30
        };

        this.meta = props?.meta || null;

    }

}

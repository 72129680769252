export class Amenity {

    id?: number;
    service_id: string;
    service_type: 'accommodation';
    label: string; // ex. Parking slot
    value?: string; // ex. parking_slot
    description?: string;
    type?: string;
    image_url?: string;
    quantity?: number;
    price?: number;
    provider_id: string;
    enabled: boolean;
    chargeable?: boolean;
    created_at?: Date;

    // Extras (not in DB)
    selected_for_booking?: boolean;
    icon?: string; // for displaying icon from environment

    constructor(props?: Amenity) {

        this.service_id = props?.service_id || null;
        this.service_type = props?.service_type || null;
        this.label = props?.label || null;
        this.value = props?.value || null;
        this.description = props?.description || null;
        this.type = props?.type || null;
        this.image_url = props?.image_url || null;
        this.quantity = props?.quantity || null;
        this.price = props?.price || null;
        this.provider_id = props?.provider_id || null;
        this.enabled = props?.enabled || false;
        this.chargeable = props?.chargeable || false;
        this.created_at = props?.created_at || null;

        this.selected_for_booking = props?.selected_for_booking || false;
        this.icon = props?.icon || null;

    }
}



export interface AmenitiesGroup {
    label?: string;
    value?: string;
    order?: number;
    amenities?: Amenity[];
}

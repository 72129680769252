<!-- TABLE LAYOUT -->

<div class="card m-1 h-100" *ngIf="displayTemplate === 'table'">

    <div class="img-wrapper">

        <a [routerLink]="['/properties', property.service_id]"
            [queryParams]="{ adults: adults, children: children, infants: infants, start_date: startDate, end_date: endDate  }" target="_blank">

            <img class="card-img-top" *ngIf="!isImgLoaded" src="/assets/img/placeholders/property-image-placeholder-1.png">
            <img class="card-img-top" [hidden]="!isImgLoaded" [src]="property.image_url" (load)="isImgLoaded = true"
                onError="this.src='/assets/img/placeholders/property-image-placeholder-1.png'">

        </a>

    </div>

    <div class="card-body pb-0">

        <div class="property-type-label">

            <h6 class="badge badge-dark badge-property-type font-size-13">

                {{utilsService.getPropertyTypeLabel(property.subcategory)}}

            </h6>

        </div>

        <div class="property-fav-btn" *ngIf="customer?.customer_id">

            <button type="button" class="btn shadow-none  px-1 py-0" (click)="addToFavorites($event, property);" *ngIf="!isFavorite">

                <i class="far fa-heart fa-hover-hidden"></i>
                <i class="fas fa-heart fa-hover-show"></i>


            </button>

            <button type="button" class="btn shadow-none  px-1 py-0" (click)="removeFromFavorites($event, property.service_id);" *ngIf="isFavorite">

                <i class="fas fa-heart"></i>

            </button>

        </div>



        <a [routerLink]="['/properties', property.service_id]"
            [queryParams]="{ adults: adults, children: children, infants: infants, start_date: startDate, end_date: endDate  }" target="_blank">

            <h6 class="text-bold" [ngClass]="isMobile ? '' : 'line-clamp-1'" title="{{property.title}}">
                {{ property.title }}
            </h6>

        </a>


        <h6 class="font-size-14">
            <i class="fas fa-map-marker-alt text-danger"></i>
            <em>
                {{property.address.city}}
            </em>
        </h6>

        <div class="pb-1">
            <rating class="text-warning h5" [(ngModel)]="property.reviews_rating.avg_rating" [max]="reviewMaxRating" readonly="true">
            </rating>
            <span class="font-size-16 text-muted pl-1">({{property.reviews_rating.total_reviews}})</span>
        </div>

        <div class="py-1 mb-1">

            <span *ngIf="property.discounted_price === null">
                <span>{{'GENERIC.LABELS.FROM' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie
                    Stay'}}</span>
                <strong class="h4">
                    {{property.price_per_month}} &euro;
                </strong>
                {{'GENERIC.LABELS.PER_MONTH' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
            </span>

            <span *ngIf="property.discounted_price !== null">
                <span>{{'GENERIC.LABELS.FROM' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie
                    Stay'}}</span>
                <strong class="h4">
                    {{property.discounted_price}} &euro;
                </strong>
                <s>
                    {{property.price_per_month}} &euro;
                </s>
                {{'GENERIC.LABELS.PER_MONTH' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
            </span>

        </div>

    </div>


    <div class="card-footer bg-white border-top">

        <div class="row pt-2" [ngClass]="{'font-size-13': !isMobile}">

            <div class="col-4 text-center font-size-13" *ngIf="property.bedrooms">

                <img class="icon-size-30" src="assets/img/generalinfo/bedroom.png"
                    title="{{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                    alt="{{property.bedrooms}} {{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}">

                <p class="mb-0 mt-1 text-truncate">
                    {{property.bedrooms}}
                    <span *ngIf="property.bedrooms === 1">

                        {{'GENERIC.LABELS.BEDROOM_SINGLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                        'Tourmie Stay'}}

                    </span>

                    <span *ngIf="property.bedrooms > 1">

                        {{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                        'Tourmie Stay'}}

                    </span>
                </p>

            </div>

            <div class="col-4 text-center font-size-13" *ngIf="property.bathrooms">

                <img class="icon-size-30" src="assets/img/generalinfo/bathroom.png"
                    title="{{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                    alt="{{property.bathrooms}} {{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}">

                <p class="mb-0 mt-1 text-truncate">
                    {{property.bathrooms}}
                    <span *ngIf="property.bathrooms === 1">

                        {{'GENERIC.LABELS.BATHROOM_SINGLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                        'Tourmie Stay'}}

                    </span>
                    <span *ngIf="property.bathrooms > 1">

                        {{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                        'Tourmie Stay'}}

                    </span>
                </p>

            </div>

            <div class="col-4 text-center font-size-13" *ngIf="property.area_size">

                <img class="icon-size-30" src="assets/img/generalinfo/area.png" alt="Area Size m^2"
                    title="{{'GENERIC.LABELS.AREA_SIZE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}">

                <p class="mb-0 mt-1 text-truncate">
                    {{property.area_size}} m<sup>2</sup>
                </p>

            </div>


        </div>

    </div>

</div>




<!-- INFO: only available for desktop view -->
<!-- LIST LAYOUT -->

<div class="card m-1" *ngIf="displayTemplate === 'list'">

    <div class="row no-gutters">

        <div class="col-md-5 text-center">

            <div class="img-wrapper">

                <a [routerLink]="['/properties', property.service_id]"
                    [queryParams]="{ adults: adults, children: children, infants: infants, start_date: startDate, end_date: endDate  }" target="_blank">

                    <img class="card-img-top rounded-0" *ngIf="!isImgLoaded" src="/assets/img/placeholders/property-image-placeholder-1.png">
                    <img class="card-img-top rounded-0" [hidden]="!isImgLoaded" [src]="property.image_url" (load)="isImgLoaded = true"
                        onError="this.src='/assets/img/placeholders/property-image-placeholder-1.png'">

                </a>

                <h6 class="badge badge-dark badge-property-type font-size-13 property-type-label">

                    {{utilsService.getPropertyTypeLabel(property.subcategory)}}
                    <!-- {{property.subcategory | titlecase }} -->

                </h6>

                <div class="property-fav-btn" *ngIf="customer?.customer_id">

                    <button type="button" class="btn shadow-none px-1 py-0" (click)="addToFavorites($event, property);" *ngIf="!isFavorite">

                        <i class="far fa-heart fa-hover-hidden"></i>
                        <i class="fas fa-heart fa-hover-show"></i>


                    </button>

                    <button type="button" class="btn shadow-none px-1 py-0" (click)="removeFromFavorites($event, property.service_id);" *ngIf="isFavorite">

                        <i class="fas fa-heart"></i>

                    </button>

                </div>

            </div>



        </div>

        <div class="col-md-7">

            <div class="card-body">
                <div class="row">
                    <div class="col-6">

                        <a [routerLink]="['/properties', property.service_id]"
                            [queryParams]="{ adults: adults, children: children, infants: infants, start_date: startDate, end_date: endDate  }" target="_blank">

                            <h6 class="text-bold line-clamp-2" title="{{property.title}}">
                                {{ property.title }}
                            </h6>

                        </a>

                        <h6 class="font-size-14">
                            <i class="fas fa-map-marker-alt text-danger"></i>
                            <em>
                                {{property.address.city}}
                            </em>
                        </h6>

                        <div>
                            <rating class="text-warning h5" [(ngModel)]="property.reviews_rating.avg_rating" [max]="reviewMaxRating" readonly="true">
                            </rating>
                            <span class="font-size-16 text-muted pl-1">({{property.reviews_rating.total_reviews}})</span>
                        </div>
                    </div>

                    <div class="col-6 text-right">

                        <span *ngIf="property.discounted_price === null">
                            <span>{{'GENERIC.LABELS.FROM' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                                'Tourmie Stay'}}</span>
                            <strong class="h4">
                                {{property.price_per_month}} &euro;
                            </strong>
                            {{'GENERIC.LABELS.PER_MONTH' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                            'Tourmie Stay'}}
                        </span>

                        <span *ngIf="property.discounted_price !== null">
                            <span>{{'GENERIC.LABELS.FROM' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                                'Tourmie Stay'}}</span>
                            <strong class="h4">
                                {{property.discounted_price}} &euro;
                            </strong>
                            <s>
                                {{property.price_per_month}} &euro;
                            </s>
                            {{'GENERIC.LABELS.PER_MONTH' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                            'Tourmie Stay'}}
                        </span>

                    </div>
                </div>


            </div>

            <div class="card-footer mb-0 pb-0 border-top bg-white">

                <div class="row pt-2">

                    <div class="col-4 text-center" *ngIf="property.bedrooms">

                        <img class="icon-size-30" src="assets/img/generalinfo/bedroom.png"
                            title="{{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                            alt="{{property.bedrooms}}  {{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}">

                        <p class="mb-0 mt-1">
                            {{property.bedrooms}}
                            <span *ngIf="property.bedrooms === 1">

                                {{'GENERIC.LABELS.BEDROOM_SINGLE' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}

                            </span>
                            <span *ngIf="property.bedrooms > 1">

                                {{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}

                            </span>
                        </p>

                    </div>


                    <div class="col-4 text-center" *ngIf="property.bathrooms">

                        <img class="icon-size-30" src="assets/img/generalinfo/bathroom.png"
                            title="{{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                            alt="{{property.bathrooms}} {{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}">

                        <p class="mb-0 mt-1">
                            {{property.bathrooms}}
                            <span *ngIf="property.bathrooms === 1">

                                {{'GENERIC.LABELS.BATHROOM_SINGLE' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}

                            </span>
                            <span *ngIf="property.bathrooms > 1">

                                {{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}

                            </span>
                        </p>

                    </div>


                    <div class="col-4 text-center" *ngIf="property.area_size">

                        <img class="icon-size-30" src="assets/img/generalinfo/area.png"
                            title=" {{'GENERIC.LABELS.AREA_SIZE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}">
                        <p class="mb-0 mt-1">
                            {{property.area_size}} m<sup>2</sup>
                        </p>

                    </div>


                </div>



            </div>

        </div>

    </div>

</div>
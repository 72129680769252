import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../../services/utils/utils.service';
import { StorageService } from '../../services/storage/storage.service';
import { FirebaseService } from '../../services/firebase/firebase.service';
import { FullStoryService } from '../../services/fullstory/fullstory.service';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Customer } from '../../models';


@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {

  public loginResponseEvent: EventEmitter<any> = new EventEmitter();


  public credentials = { email: null, password: null };
  public isConnecting = false;

  public isMobile = false;
  private isMobileSubscription: Subscription;



  constructor(
    private http: HttpClient,
    public bsModalRef: BsModalRef,
    private router: Router,
    public translate: TranslateService,
    private storageService: StorageService,
    public utilsService: UtilsService,
    private firebaseService: FirebaseService,
    private fullstoryService: FullStoryService,
    private ga: GoogleAnalyticsService
  ) {
    this.isMobile = this.utilsService.isMobile;
  }



  ngOnInit(): void {

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    const customer: Customer = this.storageService.getItem('customer'); // customer?

  }



  ngOnDestroy(): void {

    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

  }



  async submitForm(): Promise<void> {

    this.isConnecting = true;

    try {

      const customer = await this.http.post<Customer>(`${environment.params.host}/api/stay/auth/login`, this.credentials).toPromise();
      this.storageService.setItem('customer', customer);

      this.ga.setUserID(customer.customer_id);
      this.fullstoryService.identifyUser();
      this.firebaseService.updateFcmToken(customer);

      this.isConnecting = false;

      this.loginResponseEvent.emit(customer);
      this.bsModalRef.hide();


    } catch (error) {

      this.isConnecting = false;

      if (!environment.production)
        console.log(error);

      this.loginResponseEvent.emit({ error: error });

    }
  }



  goToRegistration(): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/registration']));
    window.open(url, '_blank');
  }


  closeModal(): void {
    this.loginResponseEvent.emit(null);
    this.bsModalRef.hide();
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const commonEnvironment = {
    production: true,
    environmentName: 'common',
    params: {
        host: 'https://api.prod.tourmie.com',
        host_frontend: 'https://stay.tourmie.com',
        host_frontend_domain: 'stay.tourmie.com',
        appTitle: 'Tourmie Stay',
        appVersion: '1.0.0-beta-1.4.0',
        suid: '2dd7d893-f453-4f2a-a8e3-6997de9ca871',
        gtagTrackingID: 'G-6SQWBDSWF4',
        social: {
            facebook: 'https://www.facebook.com/tourmiestay',
            instagram: 'https://www.instagram.com/tourmiestay',
            linkedin: 'https://www.linkedin.com/company/tourmie-stay'
        },
        showBetaNotification: true,
        reviewMaxRating: 5,
        listYourPropertyPageDisplayPromoModal: false,
        propertyListPageDisplayPromoModal: false,
        favoriteUndoDuration: 5000,
        accommodationDescriptionLength: 500,
        accommodationMaxAmenitiesToShow: 9,
        maintenanceMode: false,
        minBookingDays: 30,
        maxBookingDays: 365,
        providerDetailsVisibleDaysAfterBooking: 7,
        filterParams: {
            maxAdults: 10,
            minAdults: 1,
            maxChildren: 5,
            minChildren: 0,
            maxInfants: 5,
            minInfants: 0
        },
        imagesMaxFileSize: 8 * 1024 * 1024, // 8 MB
        firebase: {
            apiKey: 'AIzaSyBVQmjzt7Zf8-z6HX64O6jFsM0OfpoL-ts',
            authDomain: 'touristhub.firebaseapp.com',
            databaseURL: 'https://touristhub.firebaseio.com',
            projectId: 'touristhub',
            storageBucket: 'touristhub.appspot.com',
            messagingSenderId: '55559450439',
            appId: '1:55559450439:web:b98c3db3261f88e2fae662'
        },
        stripe: {
            pk_test: 'pk_test_51GvOq6F6TJ8ud0a5jybFHZkrKjhe1pWlWUufJUCgEyYAjLKIE1qHyb7rMTow0TIRuLE0JBwE2QhDtwYIk35HC1Je00zsCzDFfu',
            pk_live: 'pk_live_pmuFhnDeWt5eM60I6D3kVZvn00T9av4FyE',
            ca_live: 'ca_HV4nIlMdtu3GcArdPMG42fS4uil1ywqp', // for stripe connect
            ca_test: 'ca_HV4n1g9KihSMagRb06VJKnUxeLNTu5yX' // for stripe connect
        },
        fullstory: {
            orgId: '15A2KN',
            host: 'fullstory.com',
            namespace: 'FS',
            debug: false,
            script: 'edge.fullstory.com/s/fs.js'
        },
        appLanguages: [
            { language: 'English US', code: 'en', flag_url: '/assets/img/flags/rounded/united-states.png' },
            { language: 'Greek', code: 'el', flag_url: '/assets/img/flags/rounded/greece.png' },
        ],
        datatables: {
            options: {
                responsive: true,
                lengthMenu: [[25, 50, 100, 250, 500], [25, 50, 100, 250, 500]],
                language: {
                    // lengthMenu: 'Εμφάνιση _MENU_ εγγραφών ανά σελίδα',
                    // info: 'Εμφάνιση σελίδας _PAGE_ από _PAGES_', // Showing page _PAGE_ of _PAGES_
                    // infoEmpty: 'Δεν βρέθηκαν εγγραφές',
                    infoFiltered: '',
                    // search: 'Αναζήτηση',
                    // emptyTable: 'Δεν βρέθηκαν εγγραφές',
                    paginate: {
                        first: 'First',
                        last: 'Last',
                        next: 'Next',
                        previous: 'Previous',
                    }
                }
            }
        },
        googlemaps: {
            style_1: [
                {
                    featureType: 'all',
                    elementType: 'all',
                    stylers: [
                        {
                            hue: '#e7ecf0'
                        }
                    ]
                },
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'road',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: -70
                        }
                    ]
                },
                {
                    featureType: 'transit',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'simplified'
                        },
                        {
                            saturation: -60
                        }
                    ]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#d2edf9'
                        }
                    ]
                }
            ],
            clusterStyle: [
                {
                    width: 30,
                    height: 30,
                    className: 'custom-clustericon-1',
                },
                {
                    width: 35,
                    height: 35,
                    className: 'custom-clustericon-2',
                },
                {
                    width: 40,
                    height: 40,
                    className: 'custom-clustericon-3',
                }
            ]
        }
    },
    propertyTypes: [
        {
            label: 'House',
            label_gr: 'Κατοικία',
            value: 'house',
            icon: 'path/to/icon',
            selected: false,
            tempSelected: false
        },
        {
            label: 'Apartment',
            label_gr: 'Διαμέρισμα',
            value: 'apartment',
            icon: 'path/to/icon',
            selected: false,
            tempSelected: false
        },
        {
            label: 'Loft',
            label_gr: 'Λόφτ',
            value: 'loft',
            icon: 'path/to/icon',
            selected: false,
            tempSelected: false
        },
        {
            label: 'Villa',
            label_gr: 'Βίλα',
            value: 'villa',
            icon: 'path/to/icon',
            selected: false,
            tempSelected: false
        },
        {
            label: 'Room',
            label_gr: 'Δωμάτιο',
            value: 'room',
            icon: 'path/to/icon',
            selected: false,
            tempSelected: false
        },
        {
            label: 'Studio',
            label_gr: 'Στούντιο',
            value: 'studio',
            icon: 'path/to/icon',
            selected: false,
            tempSelected: false
        },
        {
            label: 'Penthouse',
            label_gr: 'Ρετιρέ',
            value: 'penthouse',
            icon: 'path/to/icon',
            selected: false,
            tempSelected: false
        },
        {
            label: 'Maisonette',
            label_gr: 'Μεζονέτα',
            value: 'maisonette',
            icon: 'path/to/icon',
            selected: false,
            tempSelected: false
        },
    ],
    accommodationGeneralInfo: [
        { label_gr: 'Εβδαδόν', label: 'Area', value: 'area_size' },
        { label_gr: 'Κρεβάτια', label: 'Beds', value: 'bedrooms' },
        { label_gr: 'Άτομα', label: 'Persons', value: 'guests' },
        { label_gr: 'Μπάνια', label: 'Baths', value: 'bathrooms' }
    ],
    propertyAmenityCategories: [
        { label_gr: 'Μπάνιο', label: 'Bathroom', value: 'bathroom', order: 20 },
        { label_gr: 'Χώρος πλυντηρίου', label: 'Laundry', value: 'laundry', order: 40 },
        { label_gr: 'Υπνοδωμάτιο', label: 'Bedroom', value: 'bedroom', order: 10 },
        { label_gr: 'Εξωτερικός χώρος', label: 'Outdoor', value: 'outdoor', order: 60 },
        { label_gr: 'Εσωτερικός χώρος', label: 'Indoor', value: 'interior', order: 50 },
        { label_gr: 'Οικογένεια', label: 'Family', value: 'family', order: 90 },
        { label_gr: 'Ψύξη', label: 'Cooling', value: 'cooling', order: 70 },
        { label_gr: 'Θέρμανση', label: 'Heating', value: 'heating', order: 80 },
        { label_gr: 'Ασφάλεια', label: 'Safety', value: 'security', order: 110 },
        { label_gr: 'Εργασία', label: 'Work', value: 'work', order: 100 },
        { label_gr: 'Κουζίνα', label: 'Kitchen', value: 'kitchen', order: 30 },
        { label_gr: 'Κτίριο', label: 'Building', value: 'building', order: 120 },
        { label_gr: 'Ψυχαγωγία', label: 'Entertainment', value: 'entertainment', order: 130 },
        { label_gr: 'Στάθμευση', label: 'Parking', value: 'parking', order: 140 },
    ],
    propertyAmenities: [
        { label: 'Bath essentials', label_gr: 'Είδη μπάνιου', value: 'bath_essentials', type: 'bathroom', icon: 'assets/img/amenities/shampoo.png', order: 100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Hairdryer', label_gr: 'Πιστολάκι μαλλιών', value: 'hairdryer', type: 'bathroom', icon: 'assets/img/amenities/hairdryer.png', order: 300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Hot water', label_gr: 'Ζεστό νερό', value: 'hot_water', type: 'bathroom', icon: 'assets/img/amenities/hot_water.png', order: 400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Towels', label_gr: 'Πετσέτσες', value: 'towels', type: 'bathroom', icon: 'assets/img/amenities/towels.png', order: 600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Βathtub', label_gr: 'Μπανιέρα', value: 'bathtub', type: 'bathroom', icon: 'assets/img/amenities/bathtub.png', order: 200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Shower', label_gr: 'Ντουζ', value: 'shower', type: 'bathroom', icon: 'assets/img/amenities/shower.png', order: 500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Bed blankets', label_gr: 'Κουβέρτες', value: 'bed_blankets', type: 'bedroom', icon: 'assets/img/amenities/bed_blankets.png', order: 700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Bed linen', label_gr: 'Κλινοσκεπάσματα', value: 'bed_linen', type: 'bedroom', icon: 'assets/img/amenities/bed_linen_and_blankets.png', order: 800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Boudoir', label_gr: 'Μπουντουάρ', value: 'boudoir', type: 'bedroom', icon: 'assets/img/amenities/boudoir.png', order: 900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Hangers', label_gr: 'Κρεμάστρες', value: 'hangers', type: 'bedroom', icon: 'assets/img/amenities/hangers.png', order: 1000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Shoe rack', label_gr: 'Ράφι παπουτσιών', value: 'shoe_rack', type: 'bedroom', icon: 'assets/img/amenities/shoe_rack.png', order: 1100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Wardrobe', label_gr: 'Ντουλάπα', value: 'wardrobe', type: 'bedroom', icon: 'assets/img/amenities/wardrobe.png', order: 1200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Elevator', label_gr: 'Ασανσέρ', value: 'elevator', type: 'building', icon: 'assets/img/amenities/elevator.png', order: 1300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Gated property', label_gr: 'Περιφραγμένη ιδιοκτησία', value: 'gated_property', type: 'building', icon: 'assets/img/amenities/gated_property.png', order: 1500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Gym', label_gr: 'Γυμναστήριο', value: 'gym', type: 'building', icon: 'assets/img/amenities/gym.png', order: 1600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Private entrance', label_gr: 'Ιδιωτική είσοδος', value: 'private_entrance', type: 'building', icon: 'assets/img/amenities/private_entrance.png', order: 1700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Shared gym', label_gr: 'Κοινόχρηστο γυμναστήριο', value: 'shared_gym', type: 'building', icon: 'assets/img/amenities/gym.png', order: 1800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Stairs', label_gr: 'Σκάλες', value: 'stairs', type: 'building', icon: 'assets/img/amenities/stairs.png', order: 1900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Garage', label_gr: 'Γκαράζ', value: 'garage', type: 'building', icon: 'assets/img/amenities/garage.png', order: 1400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Storage', label_gr: 'Αποθήκη', value: 'storage', type: 'building', icon: 'assets/img/amenities/storage.png', order: 2000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Ceiling fan', label_gr: 'Ανεμιστήρας οροφής', value: 'ceiling_fan', type: 'cooling', icon: 'assets/img/amenities/celling_fan.png', order: 2200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Air condition', label_gr: 'Κλιματιστικό', value: 'air_condition', type: 'cooling', icon: 'assets/img/amenities/air_condition.png', order: 2100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Bluetooth speaker', label_gr: 'Ηχείο bluetooth', value: 'bluetooth_speaker', type: 'entertainment', icon: 'assets/img/amenities/bluetooth_speaker.png', order: 2300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Board games', label_gr: 'Επιτραπέζια παιχνίδια', value: 'board_games', type: 'entertainment', icon: 'assets/img/amenities/board_games.png', order: 2400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Books', label_gr: 'Βιβλία', value: 'books', type: 'entertainment', icon: 'assets/img/amenities/books.png', order: 2500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Cable TV', label_gr: 'Καλωδιακή τηλεόραση', value: 'cable_tv', type: 'entertainment', icon: 'assets/img/amenities/cable_tv.png', order: 2600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Games', label_gr: 'Παιχνίδια', value: 'games', type: 'entertainment', icon: 'assets/img/amenities/games.png', order: 2700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Playroom', label_gr: 'Δωμάτιο για παιχνίδι', value: 'playroom', type: 'entertainment', icon: 'assets/img/amenities/playroom.png', order: 2800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Radio', label_gr: 'Ραδιόφωνο', value: 'radio', type: 'entertainment', icon: 'assets/img/amenities/radio.png', order: 2900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Smart TV', label_gr: 'Smart TV', value: 'smart_tv', type: 'entertainment', icon: 'assets/img/amenities/smart_tv.png', order: 3000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'TV', label_gr: 'Τηλεόραση', value: 'tv', type: 'entertainment', icon: 'assets/img/amenities/tv.png', order: 3100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Park / travel cot', label_gr: 'Πάρκο / κούνια ταξιδιού', value: 'children_park_travel_cot', type: 'family', icon: 'assets/img/amenities/children_park_travel_cot.png', order: 3400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Kids books', label_gr: 'Παιδικά βιβλία', value: 'childrens_books', type: 'family', icon: 'assets/img/amenities/childrens_books.png', order: 3500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Kids cutlery', label_gr: 'Παιδικά μαχαιροπίρουνα', value: 'cutlery_kids', type: 'family', icon: 'assets/img/amenities/cutlery_kids.png', order: 3700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Kids pool', label_gr: 'Παιδική πισίνα', value: 'kids_pool', type: 'family', icon: 'assets/img/amenities/swimming_pool.png', order: 3800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Kids tableware', label_gr: 'Παιδικά σερβίτσια', value: 'kids_tableware', type: 'family', icon: 'assets/img/amenities/kids_tableware.png', order: 3900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Wireless intercom', label_gr: 'Ασύρματη ενδοεπικοινωνία', value: 'kids_wireless_intercom', type: 'family', icon: 'assets/img/amenities/kids_wireless_intercom.png', order: 4000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Toys', label_gr: 'Παιχνίδια', value: 'toys', type: 'family', icon: 'assets/img/amenities/toys_and_tabletop_games.png', order: 4100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Baby bathtub', label_gr: 'Βρεφικό μπάνιο', value: 'baby_bathtub', type: 'family', icon: 'assets/img/amenities/baby_bathtub.png', order: 3200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Baby high chair', label_gr: 'Καρεκλάκι μωρού', value: 'baby_chair', type: 'family', icon: 'assets/img/amenities/baby_chair.png', order: 3300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Crib', label_gr: 'Κούνια', value: 'crib', type: 'family', icon: 'assets/img/amenities/crib.png', order: 3600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Fireplace', label_gr: 'Τζάκι', value: 'fireplace', type: 'heating', icon: 'assets/img/amenities/fireplace.png', order: 4200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Heater', label_gr: 'Θερμάστρα', value: 'heater', type: 'heating', icon: 'assets/img/amenities/heater.png', order: 4300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Radiator', label_gr: 'Καλοριφέρ', value: 'radiator', type: 'heating', icon: 'assets/img/amenities/radiator.png', order: 4400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Coat rack', label_gr: 'Καλόγερος', value: 'coat_rack', type: 'interior', icon: 'assets/img/amenities/coat_rack.png', order: 4600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Elevator', label_gr: 'Ασανσέρ', value: 'interior_elevator', type: 'interior', icon: 'assets/img/amenities/elevator.png', order: 4700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Gym', label_gr: 'Γυμναστήριο', value: 'interior_gym', type: 'interior', icon: 'assets/img/amenities/gym.png', order: 4800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Stairs', label_gr: 'Σκάλες', value: 'interior_stairs', type: 'interior', icon: 'assets/img/amenities/stairs.png', order: 4900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Swimming pool', label_gr: 'Πισίνα', value: 'interior_swimming_pool', type: 'interior', icon: 'assets/img/amenities/swimming_pool.png', order: 5000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Jacuzzi', label_gr: 'Τζακούζι', value: 'jacuzzi', type: 'interior', icon: 'assets/img/amenities/jacuzzi.png', order: 5100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Winter carpets', label_gr: 'Χειμερινά χαλιά', value: 'winter_carpets', type: 'interior', icon: 'assets/img/amenities/winter_carpets.png', order: 5400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Bookshelf', label_gr: 'Ράφι βιβλίων', value: 'bookshelf', type: 'interior', icon: 'assets/img/amenities/bookshelf.png', order: 4500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Sofa', label_gr: 'Καναπές', value: 'sofa', type: 'interior', icon: 'assets/img/amenities/sofa.png', order: 5200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Sofa bed', label_gr: 'Καναπές-κρεβάτι', value: 'sofa_bed', type: 'interior', icon: 'assets/img/amenities/sofa_bed.png', order: 5300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Blender', label_gr: 'Μίξερ', value: 'blender', type: 'kitchen', icon: 'assets/img/amenities/blender.png', order: 5500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Crockpot', label_gr: 'Ηλεκτρική γάστρα', value: 'crockpot', type: 'kitchen', icon: 'assets/img/amenities/crockpot.png', order: 5700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Cutlery', label_gr: 'Μαχαιροπίρουνα', value: 'cutlery_silver', type: 'kitchen', icon: 'assets/img/amenities/cutlery_silver.png', order: 5800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Dining table', label_gr: 'Τραπεζαρία', value: 'dining_table', type: 'kitchen', icon: 'assets/img/amenities/dining_table.png', order: 5900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Rice cooker', label_gr: 'Παρασκευαστής ρυζιού', value: 'rice_cooker', type: 'kitchen', icon: 'assets/img/amenities/rice_cooker.png', order: 6400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Toaster', label_gr: 'Τοστιέρα', value: 'toaster', type: 'kitchen', icon: 'assets/img/amenities/toaster.png', order: 6600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Kettle', label_gr: 'Βραστήρας νερού', value: 'water_boiler', type: 'kitchen', icon: 'assets/img/amenities/water_boiler.png', order: 6700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Coffee machine', label_gr: 'Καφετιέρα', value: 'coffee_machine', type: 'kitchen', icon: 'assets/img/amenities/coffee_machine.png', order: 5600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Dishwasher', label_gr: 'Πλυντήριο πιάτων', value: 'dish_washer', type: 'kitchen', icon: 'assets/img/amenities/dish_washer.png', order: 6000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Fridge', label_gr: 'Ψυγείο', value: 'fridge', type: 'kitchen', icon: 'assets/img/amenities/fridge.png', order: 6100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Microwave', label_gr: 'Φούρνος μικροκυμάτων', value: 'microwave', type: 'kitchen', icon: 'assets/img/amenities/microwave.png', order: 6200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Oven', label_gr: 'Φούρνος', value: 'oven', type: 'kitchen', icon: 'assets/img/amenities/oven.png', order: 6300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Stove', label_gr: 'Κουζίνα', value: 'stove', type: 'kitchen', icon: 'assets/img/amenities/stove.png', order: 6500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Clothes drying rack', label_gr: 'Απλώστρα', value: 'clothes_drying_rack', type: 'laundry', icon: 'assets/img/amenities/clothes_drying_rack.png', order: 6800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Iron', label_gr: 'Σίδερο', value: 'iron', type: 'laundry', icon: 'assets/img/amenities/iron.png', order: 7000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Ironing board', label_gr: 'Σιδερώστρα', value: 'ironing_board', type: 'laundry', icon: 'assets/img/amenities/ironing_board.png', order: 7100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Laundry essentials', label_gr: 'Είδη πλυντηρίου', value: 'laundry_essentials', type: 'laundry', icon: 'assets/img/amenities/essentials.png', order: 7200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Dryer', label_gr: 'Στεγνωτήριο', value: 'dryer', type: 'laundry', icon: 'assets/img/amenities/dryer.png', order: 6900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Washing machine', label_gr: 'Πλυντήριο', value: 'washing_machine', type: 'laundry', icon: 'assets/img/amenities/washing_machine.png', order: 7300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'BBQ', label_gr: 'Μπάρμεκιου', value: 'bbq', type: 'outdoor', icon: 'assets/img/amenities/bbq.png', order: 7500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Jacuzzi', label_gr: 'Τζακούζι', value: 'outdoor_jacuzzi', type: 'outdoor', icon: 'assets/img/amenities/jacuzzi.png', order: 7700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Living area', label_gr: 'Χώρος καθιστικού', value: 'living_area', type: 'outdoor', icon: 'assets/img/amenities/living_area.png', order: 7800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Open-air shower', label_gr: 'Εξωτερικό ντουζ', value: 'outdoor_shower', type: 'outdoor', icon: 'assets/img/amenities/outdoor_shower.png', order: 7900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Dining area', label_gr: 'Χώρος τραπεζαρίας', value: 'outdoor_dining_area', type: 'outdoor', icon: 'assets/img/amenities/outdoor_dining_area.png', order: 8000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Private yard', label_gr: 'Ιδιωτική αυλή', value: 'private_yard', type: 'outdoor', icon: 'assets/img/amenities/yard.png', order: 8100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Shared garden', label_gr: 'Κοινόχρηστος κήπος', value: 'shared_garden', type: 'outdoor', icon: 'assets/img/amenities/garden.png', order: 8200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Shared yard', label_gr: 'Κοινόχρηστη αυλή', value: 'shared_yard', type: 'outdoor', icon: 'assets/img/amenities/yard.png', order: 8300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Sun loungers & umbrellas', label_gr: 'Ξαπλώστρες & ομπρέλες', value: 'sun_loungers_and_umbrellas', type: 'outdoor', icon: 'assets/img/amenities/sun_loungers_and_umbrellas.png', order: 8400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Balcony', label_gr: 'Μπαλκόνι', value: 'balcony', type: 'outdoor', icon: 'assets/img/amenities/balcony.png', order: 7400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Garden', label_gr: 'Κήπος', value: 'garden', type: 'outdoor', icon: 'assets/img/amenities/garden.png', order: 7600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Swimming pool', label_gr: 'Πισίνα', value: 'swimming_pool', type: 'outdoor', icon: 'assets/img/amenities/swimming_pool.png', order: 8500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Terrace', label_gr: 'Βεράντα', value: 'terrace', type: 'outdoor', icon: 'assets/img/amenities/terrace.png', order: 8600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Street parking', label_gr: 'Δημόσιος χώρος στάθμευσης', value: 'street_parking', type: 'parking', icon: 'assets/img/amenities/parking_slots.png', order: 8800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Private parking', label_gr: 'Ιδιωτικός χώρος στάθμευσης', value: 'parking_slots', type: 'parking', icon: 'assets/img/amenities/parking_slots.png', order: 8700, selected: false, tempSelected: false, chargeable: true, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Carbon monoxide alarm', label_gr: 'Ανιχνευτής μονοξειδίου του άνθρακα', value: 'carbon_monoxide_alarm', type: 'security', icon: 'assets/img/amenities/carbon_monoxide_alarm.png', order: 8900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Double-glazed balcony door', label_gr: 'Μπαλκονόπορτα με διπλά τζάμια', value: 'double_glazed_doors', type: 'security', icon: 'assets/img/amenities/glazed_windows.png', order: 9000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Double-glazed windows', label_gr: 'Παράθυρα με διπλά τζάμια', value: 'double_glazed_windows', type: 'security', icon: 'assets/img/amenities/glazed_doors.png', order: 9100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Fire extinguisher', label_gr: 'Πυροσβεστήρες', value: 'fire_extinguisher', type: 'security', icon: 'assets/img/amenities/fire_extinguisher.png', order: 9200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'First aid kit', label_gr: 'Κουτί πρώτων βοηθειών', value: 'first_aid_kit', type: 'security', icon: 'assets/img/amenities/first_aid_kit.png', order: 9300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Intercom system', label_gr: 'Σύστημα ενδοεπικοινωνίας', value: 'intercom_system', type: 'security', icon: 'assets/img/amenities/intercom_system.png', order: 9400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Lockbox', label_gr: 'Χρηματοκιβώτιο', value: 'lockbox', type: 'security', icon: 'assets/img/amenities/lockbox.png', order: 9500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Security alarm system', label_gr: 'Σύστημα συναγερμού ασφαλειας', value: 'security_alarm_system', type: 'security', icon: 'assets/img/amenities/security_alarm_system.png', order: 9600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Security cameras', label_gr: 'Κάμερες ασφαλειας', value: 'security_cameras', type: 'security', icon: 'assets/img/amenities/security_cameras.png', order: 9700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Security door', label_gr: 'Πόρτα ασφαλείας', value: 'security_doors', type: 'security', icon: 'assets/img/amenities/security_doors.png', order: 9800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Smoke detector', label_gr: 'Ανιχνευτής καπνού', value: 'smoke_detector', type: 'security', icon: 'assets/img/amenities/smoke_detector.png', order: 9900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Triple-glazed balcony door', label_gr: 'Μπαλκονόπορτα με τριπλά τζάμια', value: 'triple_glazed_doors', type: 'security', icon: 'assets/img/amenities/glazed_doors.png', order: 10000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Triple-glazed windows', label_gr: 'Παράθυρα με τριπλά τζάμια', value: 'triple_glazed_windows', type: 'security', icon: 'assets/img/amenities/glazed_windows.png', order: 10100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Office lighting', label_gr: 'Φωτισμός γραφείου', value: 'office_lighting', type: 'work', icon: 'assets/img/amenities/office_lighting.png', order: 10300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Office chair', label_gr: 'Καρέκλα γραφείου', value: 'office_chair', type: 'work', icon: 'assets/img/amenities/office_chair.png', order: 10400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Office supplies', label_gr: 'Είδη γραφείου', value: 'office_supplies', type: 'work', icon: 'assets/img/amenities/office_supplies.png', order: 10500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Office desk', label_gr: 'Γραφείο', value: 'working_desk', type: 'work', icon: 'assets/img/amenities/office_desk.png', order: 10700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
        { label: 'Ethernet', label_gr: 'Προσαρμογέας δικτύου', value: 'ethernet', type: 'work', icon: 'assets/img/amenities/ethernet.png', order: 10200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Wi-Fi', label_gr: 'Ίντερντετ', value: 'wifi', type: 'work', icon: 'assets/img/amenities/wifi.png', order: 10600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Workspace', label_gr: 'Χώρος εργασίας', value: 'workspace', type: 'work', icon: 'assets/img/amenities/working_desk.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Freezer', label_gr: 'Καταψύκτης', value: 'freezer', type: 'kitchen', icon: 'assets/img/amenities/freezer.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Wine glasses', label_gr: 'Ποτήρια κρασιού', value: 'wine_glasses', type: 'kitchen', icon: 'assets/img/amenities/wine-glasses.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
        { label: 'Fan', label_gr: 'Ανεμιστήρας', value: 'fan', type: 'cooling', icon: 'assets/img/amenities/fan.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    ],
    propertyLicenseNumberTypes: [
        { label_gr: 'Αριθμός Μητρώου Ακινήτου (Α.Μ.Α)', label: 'Property Registration Number (A.M.A.)', value: 'prn' },
        { label_gr: 'Ειδικό Σήμα Λειτουργίας - Αριθμός Μητρώου Τουριστικών Επιχειρήσεων ( ΕΣΛ - MHTE) ή Μοναδικός Αριθμός Γνωστοποίησης (ΜΑΓ)', label: 'Special Operating Logo (ESL - MHTE) or Notify Business Number (MAG)', value: 'esl_mag' },
        { label_gr: 'Ισχύει εξαίρεση για το ακίνητό μου', label: 'My property has an exception', value: 'exception' }
    ],
    propertyBedTypes: [
        { label: 'Double bed', label_gr: 'Διπλό κρεβάτι', value: 'double_beds' },
        { label: 'Single bed', label_gr: 'Μονό κρεβάτι', value: 'single_beds' },
        { label: 'Sofa bed', label_gr: 'Καναπές κρεβάτι', value: 'sofa_beds' },
        { label: 'Couch', label_gr: 'Καναπές', value: 'couches' },
        { label: 'Child bed', label_gr: 'Παιδικό κρεβάτι', value: 'child_beds' },
        { label: 'Queen size bed', label_gr: 'Υπέρδιπλο κρεβάτι (queen)', value: 'queen_size_beds' },
        { label: 'King size bed', label_gr: 'Υπέρδιπλο κρεβάτι (king)', value: 'king_size_beds' },
    ],
    serviceExtraCosts: [
        // { label_gr: 'Ενοίκιο διαμονής', label: 'Accommodation rental', value: 'accommodation_price', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 10 },
        { label_gr: 'Κοινόχρηστα', label: 'Maintenance expenses', value: 'maintenance_expenses', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 20 },
        { label_gr: 'Ηλεκτρικό ρεύμα', label: 'Electricity', value: 'electricity', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 30 },
        { label_gr: 'Νερό', label: 'Water', value: 'water', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 40 },
        { label_gr: 'Θέρμανση', label: 'Heat', value: 'heat', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 50 },
        { label_gr: 'Καθαρισμός χώρου', label: 'Cleaning service', value: 'cleaning_service', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 60 },
        { label_gr: 'Καθαριστήριο', label: 'Laundry service', value: 'laundry_service', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 70 },
        { label_gr: 'Κλινοσκεπάσματα', label: 'Linen & towels', value: 'linen_and_towels', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 80 },
        { label_gr: 'Καθαρισμός κήπου', label: 'Garden maintenance', value: 'garden_maintenance', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 90 },
        { label_gr: 'Καθαρισμός πισίνας', label: 'Pool maintenance', value: 'pool_maintenance', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 100 },
        { label_gr: 'Κατοικίδια', label: 'Pets', value: 'pets', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 110 },
        { label_gr: 'WiFi', label: 'WiFi', value: 'wifi', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 120 },
        { label_gr: 'Αποθήκη', label: 'Storage', value: 'storage', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 130 },
    ],
    bookingParams: {
        sources: [
            { label: 'Airbnb.com', value: 'airbnb.com' },
            { label: 'Booking.com', value: 'booking.com' },
            { label: 'My Website', value: 'business_website' },
            { label: 'VRBO', value: 'vrbo' },
            { label: 'Expedia', value: 'expedia' },
            { label: 'Phone Call', value: 'phone_call' },
            { label: 'Tourmie Stay', value: 'tourmie_stay' },
            { label: 'Other', value: 'other' },
        ],
        types: [
            { label_gr: 'Απευθείας', label: 'Direct', value: 'direct' },
            { label_gr: 'Κατόπιν αιτήματος', label: 'On Request', value: 'request' },
        ]
    },
    identity_verification_types: [
        { label_gr: 'Αστυνομική ταυτότητα', label: 'Identity card', value: 'identity_card' },
        { label_gr: 'Διαβατήριο', label: 'Passport', value: 'passport' },
        { label_gr: 'Δίπλωμα οδήγησης', label: 'Driving license', value: 'driving_license' },
    ],
    identity_image_types: [
        { label_gr: 'Μπροστινή πλευρά της αστυνομικής ταυτότητας', label: 'ID card front side', value: 'identity_card_front', type: 'identity_card' },
        { label_gr: 'Πίσω πλευρά της αστυνομικής ταυτότητας', label: 'ID card back side', value: 'identity_card_back', type: 'identity_card' },
        { label_gr: 'Selfie φωτογραφία', label: 'Selfie picture', value: 'identity_card_selfie', type: 'identity_card' },
        { label_gr: 'Σελίδα διαβατηρίου', label: 'Passport page', value: 'passport_page', type: 'passport' },
        { label_gr: 'Selfie φωτογραφία', label: 'Selfie picture', value: 'passport_selfie', type: 'passport' },
        { label_gr: 'Μπροστινή πλευρά του διπλώματους οδήγησης', label: 'License card front side', value: 'driving_license_front', type: 'driving_license' },
        { label_gr: 'Selfie φωτογραφία', label: 'Selfie picture', value: 'driving_license_selfie', type: 'driving_license' },
    ],
    bookingStatuses: [
        { label_gr: 'Απορρίφθηκε', label: 'Rejected', value: 'rejected' },
        { label_gr: 'Επιβεβαιώθηκε', label: 'Confirmed', value: 'confirmed' },
        { label_gr: 'Εκκρεμεί', label: 'Pending', value: 'pending' },
        { label_gr: 'Ακυρώθηκε', label: 'Cancelled', value: 'canceled' },
        { label_gr: 'Αρχειοθετήθηκε', label: 'Archived', value: 'archived' },
    ],
    bookingPaymentStatuses: [
        { label_gr: 'Πληρώθηκε', label: 'Paid', value: 'paid' },
        { label_gr: 'Ακυρώθηκε', label: 'Cancelled', value: 'canceled' },
        { label_gr: 'Σε εκρεμμότητα', label: 'Pending', value: 'pending' },
        { label_gr: 'Απαιτεί μέθοδο πληρωμής', label: 'Requires payment method', value: 'requires_payment_method' },
        { label_gr: 'Απαιτεί επιβεβαίωση', label: 'Requires confirmation', value: 'requires_confirmation' },
        { label_gr: 'Απαιτεί ενέργεια', label: 'Requires action', value: 'requires_action' },
        { label_gr: 'Σε επεξεργασία', label: 'Processing', value: 'processing' },
        { label_gr: 'Απαιτεί λήψη', label: 'Requires capture', value: 'requires_capture' },
        { label_gr: 'Ολοκληρωμένη', label: 'Succeeded', value: 'succeeded' },
    ],
    faq: {
        categories: {
            general: {
                title_translation_key: 'PAGES.FAQ_PAGE.FAQ_GENERAL_TITLE',
                categories: [
                    { value: 'about', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_GENERAL_CATEGORY_ABOUT', },
                ],
                questions: [
                    { category: 'about', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_GENERAL_GENERAL_WHAT_IS_A_TOURMIE_STAY', },
                    { category: 'about', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_GENERAL_GENERAL_WHY_SHOULD_I_TRUST_TOURMIE_STAY', },
                ]
            },
            tenants: {
                title_translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_TITLE',
                categories: [
                    { value: 'account', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_ACCOUNT', },
                    { value: 'payments', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_PAYMENTS', },
                    { value: 'services', order: 30, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_SERVICES', },
                    { value: 'reservation_policy', order: 40, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_RESERVATION_POLICY', },
                    { value: 'properties', order: 50, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_PROPERTIES', },
                ],
                questions: [
                    { category: 'account', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_ACCOUNT_HOW_DO_I_CREATE_A_TOURMIE_STAY_ACCOUNT_AS_A_TENANT' },
                    { category: 'account', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_ACCOUNT_CAN_I_DELETE_MY_ACCOUNT' },
                    { category: 'account', order: 30, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_ACCOUNT_WHAT_IS_THE_REGISTRATION_FEE' },
                    { category: 'payments', order: 40, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_HOW_AND_WHEN_DO_I_PAY_THE_MONTHLY_RENT' },
                    { category: 'payments', order: 50, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_HOW_DO_I_ENTER_MY_CARD_DETAILS' },
                    { category: 'payments', order: 60, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_IS_THE_PAYMENT_METHOD_USED_BY_TOURMIE_STAY_SECURE' },
                    { category: 'payments', order: 70, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_WHO_WILL_ISSUE_THE_MONTHLY_RENT_PAYMENT_RECEIPT' },
                    { category: 'payments', order: 80, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_HOW_ARE_THE_MONTHLY_FIXED_CHARGE_AND_MAINTENANCE_FEES_OF_THE_ACCOMMODATION_BEEN_PAID' },
                    { category: 'payments', order: 90, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_DO_I_NEED_TO_PAY_ANY_DEPOSIT_FOR_THE_ACCOMMODATION' },
                    { category: 'payments', order: 100, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_DO_I_NEED_TO_PROVIDE_A_GUARANTEE_FOR_THE_ACCOMMODATION_IN_CASE_OF_DAMAGE_OR_LOSS' },
                    { category: 'properties', order: 110, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_WHO_CAN_STAY_IN_TOURMIE_STAY_ACCOMMODATION' },
                    { category: 'properties', order: 120, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_HOW_LONG_CAN_I_BOOK_AN_ACCOMMODATION' },
                    { category: 'properties', order: 130, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_CAN_I_STAY_FOR_MORE_THAN_A_YEAR' },
                    { category: 'properties', order: 140, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_WHAT_IS_THE_MINIMUM_BOOKING_PERIOD_FOR_RENTING_AN_ACCOMMODATION' },
                    { category: 'properties', order: 150, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_WHAT_IS_THE_PROCEDURE_I_NEED_TO_FOLLOW_TO_BOOK_A_TOURMIE_STAY_ACCOMODATION' },
                    { category: 'reservation_policy', order: 160, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_RESERVATION_POLICY_CAN_I_CANCEL_MY_RESERVATION' },
                    { category: 'reservation_policy', order: 170, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_RESERVATION_POLICY_WHAT_HAPPENS_IF_THE_ACCOMMODATION_DOES_NOT_RESPONSIVE_THE_DESCRIPTION_OF_THE_LISTING' },
                    { category: 'services', order: 180, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_SERVICES_ARE_PETS_ALLOWED' },
                    { category: 'services', order: 190, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_SERVICES_ARE_PARTIES_OR_EVENTS_ALLOWED' },
                    { category: 'services', order: 200, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_SERVICES_IS_THE_ACCOMMODATION_FULLY_EQUIPPED_AND_FURNISHED_UPON_MY_BOOKING' },
                    { category: 'services', order: 210, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_SERVICES_DOES_THE_PROPERTY_HAVE_A_CLEANING_SERVICE' },
                ]
            },
            landlords: {
                title_translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_TITLE',
                categories: [
                    { value: 'account', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_ACCOUNT', },
                    { value: 'payments', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_PAYMENTS', },
                    { value: 'services', order: 30, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_SERVICES', },
                    { value: 'basic_info', order: 40, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_BASIC_INFO', },
                    { value: 'properties', order: 50, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_PROPERTIES', },
                ],
                questions: [
                    { category: 'account', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_ACCOUNT_HOW_DO_I_CREATE_A_TOURMIE_STAY_ACCOUNT_AS_A_TENANT' },
                    { category: 'account', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_ACCOUNT_CAN_I_DELETE_MY_ACCOUNT' },
                    { category: 'account', order: 30, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_ACCOUNT_WHAT_IS_THE_REGISTRATION_FEE' },
                    { category: 'account', order: 40, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_ACCOUNT_WHAT_COMMISSION_DOES_THE_TOURMIE_STAY_RECEIVE_ON_EACH_BOOKING' },
                    { category: 'payments', order: 50, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_WHEN_DO_I_GET_PAID_THE_MONTHLY_RENT' },
                    { category: 'payments', order: 60, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_CAN_I_CHARGE_EXTRA_FOR_SERVICES_E_G_PARKING_CLEANING' },
                    { category: 'payments', order: 70, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_CAN_I_INCORPORATE_THE_MONTHLY_COSTS_OF_THE_PROPERTY_ALONG_WITH_THE_MONTHLY_RENT' },
                    { category: 'payments', order: 80, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_WHO_ISSUES_THE_MONTHLY_RENT_PAYMENT_RECEIPT' },
                    { category: 'payments', order: 90, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_DO_I_NEED_TO_RECIEVE_A_DEPOSIT_FOR_MY_ACCOMMODATION' },
                    { category: 'payments', order: 100, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_WHAT_IS_THE_MINIMUM_RENT_I_CAN_SET_IN_TOURMIE_STAY_FOR_MY_ACCOMODATION' },
                    { category: 'payments', order: 110, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_IN_CASE_OF_DAMAGE_TO_MY_ACCOMMODATION_HOW_CAN_I_BE_COMPENSATED' },
                    { category: 'payments', order: 120, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_WHAT_HAPPENS_IF_A_TENANT_CLAIMS_THAT_MY_ACCOMMODATION_DOES_NOT_RESPOND_THE_DESCRIPTION_OF_THE_LISTING' },
                    { category: 'services', order: 130, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_SERVICES_WHAT_DOES_MY_ACCOMMODATION_NEED_TO_HAVE_IN_ORDER_TO_REGISTER_WITH_TOURMIE_STAY' },
                    { category: 'services', order: 140, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_SERVICES_WHAT_FACILITIES_CAN_I_PROVIDE_TO_MY_GUESTS' },
                    { category: 'services', order: 150, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_SERVICES_IN_TOURMIE_STAY_DOES_THE_PROPERTY_NEED_TO_BE_FURNISHED' },
                    { category: 'basic_info', order: 160, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_BASIC_INFO_WHAT_ARE_THE_MINIMUM_REQUIREMENTS_TO_REGISTER_MY_ACCOMMODATION_IN_TOURMIE_STAY' },
                    { category: 'basic_info', order: 170, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_BASIC_INFO_DOES_MY_PROPERTY_NEED_TO_HAVE_PROPERTY_REGISTRATION_NUMBER_A_M_A' },
                    { category: 'basic_info', order: 180, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_BASIC_INFO_CAN_A_TENANT_CONTACT_ME_OFF_PLATFORM' },
                    { category: 'basic_info', order: 190, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_BASIC_INFO_CAN_I_ADD_A_CANCELLATION_POLICY' },
                    { category: 'properties', order: 200, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_WHAT_ARE_MY_OBLIGATIONS_TOWARD_THE_GUESTS_WHO_WILL_USE_MY_ACCOMMODATION' },
                    { category: 'properties', order: 210, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_WHAT_KIND_OF_ACCOMMODATIONS_CAN_BE_REGISTERED' },
                    { category: 'properties', order: 220, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_CAN_I_REGISTER_A_HOTEL_ROOM_THAT_HAS_A_SPECIAL_OPERATING_LOGO_ESL' },
                    { category: 'properties', order: 230, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_WHAT_IS_THE_MINIMUM_LENGTH_OF_STAY' },
                    { category: 'properties', order: 240, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_WHAT_HAPPENS_IF_THE_TENANT_CAUSES_ANY_DAMAGE_TO_MY_PROPERTY' },
                    { category: 'properties', order: 250, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_DOES_TOURMIE_STAY_OFFER_PROPERTY_INSURANCE' },
                ]
            }
        }
    },
    countries: [
        { name: 'Afghanistan', code: 'AF', vatPrefix: null },
        { name: 'Åland Islands', code: 'AX', vatPrefix: null },
        { name: 'Albania', code: 'AL', vatPrefix: null },
        { name: 'Algeria', code: 'DZ', vatPrefix: null },
        { name: 'American Samoa', code: 'AS', vatPrefix: null },
        { name: 'Andorra', code: 'AD', vatPrefix: null },
        { name: 'Angola', code: 'AO', vatPrefix: null },
        { name: 'Anguilla', code: 'AI', vatPrefix: null },
        { name: 'Antarctica', code: 'AQ', vatPrefix: null },
        { name: 'Antigua and Barbuda', code: 'AG', vatPrefix: null },
        { name: 'Argentina', code: 'AR', vatPrefix: null },
        { name: 'Armenia', code: 'AM', vatPrefix: null },
        { name: 'Aruba', code: 'AW', vatPrefix: null },
        { name: 'Australia', code: 'AU', vatPrefix: null },
        { name: 'Austria', code: 'AT', vatPrefix: 'AT' },
        { name: 'Azerbaijan', code: 'AZ', vatPrefix: null },
        { name: 'Bahamas', code: 'BS', vatPrefix: null },
        { name: 'Bahrain', code: 'BH', vatPrefix: null },
        { name: 'Bangladesh', code: 'BD', vatPrefix: null },
        { name: 'Barbados', code: 'BB', vatPrefix: null },
        { name: 'Belarus', code: 'BY', vatPrefix: null },
        { name: 'Belgium', code: 'BE', vatPrefix: 'BE' },
        { name: 'Belize', code: 'BZ', vatPrefix: null },
        { name: 'Benin', code: 'BJ', vatPrefix: null },
        { name: 'Bermuda', code: 'BM', vatPrefix: null },
        { name: 'Bhutan', code: 'BT', vatPrefix: null },
        { name: 'Bolivia', code: 'BO', vatPrefix: null },
        { name: 'Bosnia and Herzegovina', code: 'BA', vatPrefix: null },
        { name: 'Botswana', code: 'BW', vatPrefix: null },
        { name: 'Bouvet Island', code: 'BV', vatPrefix: null },
        { name: 'Brazil', code: 'BR', vatPrefix: null },
        { name: 'British Indian Ocean Territory', code: 'IO', vatPrefix: null },
        { name: 'Brunei Darussalam', code: 'BN', vatPrefix: null },
        { name: 'Bulgaria', code: 'BG', vatPrefix: 'BG' },
        { name: 'Burkina Faso', code: 'BF', vatPrefix: null },
        { name: 'Burundi', code: 'BI', vatPrefix: null },
        { name: 'Cambodia', code: 'KH', vatPrefix: null },
        { name: 'Cameroon', code: 'CM', vatPrefix: null },
        { name: 'Canada', code: 'CA', vatPrefix: null },
        { name: 'Cape Verde', code: 'CV', vatPrefix: null },
        { name: 'Cayman Islands', code: 'KY', vatPrefix: null },
        { name: 'Central African Republic', code: 'CF', vatPrefix: null },
        { name: 'Chad', code: 'TD', vatPrefix: null },
        { name: 'Chile', code: 'CL', vatPrefix: null },
        { name: 'China', code: 'CN', vatPrefix: null },
        { name: 'Christmas Island', code: 'CX', vatPrefix: null },
        { name: 'Cocos (Keeling) Islands', code: 'CC', vatPrefix: null },
        { name: 'Colombia', code: 'CO', vatPrefix: null },
        { name: 'Comoros', code: 'KM', vatPrefix: null },
        { name: 'Congo', code: 'CG', vatPrefix: null },
        { name: 'Congo, The Democratic Republic of the', code: 'CD', vatPrefix: null },
        { name: 'Cook Islands', code: 'CK', vatPrefix: null },
        { name: 'Costa Rica', code: 'CR', vatPrefix: null },
        { name: 'Cote D\'Ivoire', code: 'CI', vatPrefix: null },
        { name: 'Croatia', code: 'HR', vatPrefix: 'HR' },
        { name: 'Cuba', code: 'CU', vatPrefix: null },
        { name: 'Cyprus', code: 'CY', vatPrefix: 'CY' },
        { name: 'Czech Republic', code: 'CZ', vatPrefix: 'CZ' },
        { name: 'Denmark', code: 'DK', vatPrefix: 'DK' },
        { name: 'Djibouti', code: 'DJ', vatPrefix: null },
        { name: 'Dominica', code: 'DM', vatPrefix: null },
        { name: 'Dominican Republic', code: 'DO', vatPrefix: null },
        { name: 'Ecuador', code: 'EC', vatPrefix: null },
        { name: 'Egypt', code: 'EG', vatPrefix: null },
        { name: 'El Salvador', code: 'SV', vatPrefix: null },
        { name: 'Equatorial Guinea', code: 'GQ', vatPrefix: null },
        { name: 'Eritrea', code: 'ER', vatPrefix: null },
        { name: 'Estonia', code: 'EE', vatPrefix: 'EE' },
        { name: 'Ethiopia', code: 'ET', vatPrefix: null },
        { name: 'Falkland Islands (Malvinas)', code: 'FK', vatPrefix: null },
        { name: 'Faroe Islands', code: 'FO', vatPrefix: null },
        { name: 'Fiji', code: 'FJ', vatPrefix: null },
        { name: 'Finland', code: 'FI', vatPrefix: 'FI' },
        { name: 'France', code: 'FR', vatPrefix: 'FR' },
        { name: 'French Guiana', code: 'GF', vatPrefix: null },
        { name: 'French Polynesia', code: 'PF', vatPrefix: null },
        { name: 'French Southern Territories', code: 'TF', vatPrefix: null },
        { name: 'Gabon', code: 'GA', vatPrefix: null },
        { name: 'Gambia', code: 'GM', vatPrefix: null },
        { name: 'Georgia', code: 'GE', vatPrefix: null },
        { name: 'Germany', code: 'DE', vatPrefix: 'DE' },
        { name: 'Ghana', code: 'GH', vatPrefix: null },
        { name: 'Gibraltar', code: 'GI', vatPrefix: null },
        { name: 'Greece', code: 'GR', vatPrefix: 'EL' },
        { name: 'Greenland', code: 'GL', vatPrefix: null },
        { name: 'Grenada', code: 'GD', vatPrefix: null },
        { name: 'Guadeloupe', code: 'GP', vatPrefix: null },
        { name: 'Guam', code: 'GU', vatPrefix: null },
        { name: 'Guatemala', code: 'GT', vatPrefix: null },
        { name: 'Guernsey', code: 'GG', vatPrefix: null },
        { name: 'Guinea', code: 'GN', vatPrefix: null },
        { name: 'Guinea-Bissau', code: 'GW', vatPrefix: null },
        { name: 'Guyana', code: 'GY', vatPrefix: null },
        { name: 'Haiti', code: 'HT', vatPrefix: null },
        { name: 'Heard Island and Mcdonald Islands', code: 'HM', vatPrefix: null },
        { name: 'Holy See (Vatican City State)', code: 'VA', vatPrefix: null },
        { name: 'Honduras', code: 'HN', vatPrefix: null },
        { name: 'Hong Kong', code: 'HK', vatPrefix: null },
        { name: 'Hungary', code: 'HU', vatPrefix: 'HU' },
        { name: 'Iceland', code: 'IS', vatPrefix: null },
        { name: 'India', code: 'IN', vatPrefix: null },
        { name: 'Indonesia', code: 'ID', vatPrefix: null },
        { name: 'Iran, Islamic Republic Of', code: 'IR', vatPrefix: null },
        { name: 'Iraq', code: 'IQ', vatPrefix: null },
        { name: 'Ireland', code: 'IE', vatPrefix: 'IE' },
        { name: 'Isle of Man', code: 'IM', vatPrefix: null },
        { name: 'Israel', code: 'IL', vatPrefix: null },
        { name: 'Italy', code: 'IT', vatPrefix: 'IT' },
        { name: 'Jamaica', code: 'JM', vatPrefix: null },
        { name: 'Japan', code: 'JP', vatPrefix: null },
        { name: 'Jersey', code: 'JE', vatPrefix: null },
        { name: 'Jordan', code: 'JO', vatPrefix: null },
        { name: 'Kazakhstan', code: 'KZ', vatPrefix: null },
        { name: 'Kenya', code: 'KE', vatPrefix: null },
        { name: 'Kiribati', code: 'KI', vatPrefix: null },
        { name: 'Korea, Democratic People\'S Republic of', code: 'KP', vatPrefix: null },
        { name: 'Korea, Republic of', code: 'KR', vatPrefix: null },
        { name: 'Kuwait', code: 'KW', vatPrefix: null },
        { name: 'Kyrgyzstan', code: 'KG', vatPrefix: null },
        { name: 'Lao People\'S Democratic Republic', code: 'LA', vatPrefix: null },
        { name: 'Latvia', code: 'LV', vatPrefix: 'LV' },
        { name: 'Lebanon', code: 'LB', vatPrefix: null },
        { name: 'Lesotho', code: 'LS', vatPrefix: null },
        { name: 'Liberia', code: 'LR', vatPrefix: null },
        { name: 'Libyan Arab Jamahiriya', code: 'LY', vatPrefix: null },
        { name: 'Liechtenstein', code: 'LI', vatPrefix: null },
        { name: 'Lithuania', code: 'LT', vatPrefix: 'LT' },
        { name: 'Luxembourg', code: 'LU', vatPrefix: 'LU' },
        { name: 'Macao', code: 'MO', vatPrefix: null },
        { name: 'Madagascar', code: 'MG', vatPrefix: null },
        { name: 'Malawi', code: 'MW', vatPrefix: null },
        { name: 'Malaysia', code: 'MY', vatPrefix: null },
        { name: 'Maldives', code: 'MV', vatPrefix: null },
        { name: 'Mali', code: 'ML', vatPrefix: null },
        { name: 'Malta', code: 'MT', vatPrefix: 'MT' },
        { name: 'Marshall Islands', code: 'MH', vatPrefix: null },
        { name: 'Martinique', code: 'MQ', vatPrefix: null },
        { name: 'Mauritania', code: 'MR', vatPrefix: null },
        { name: 'Mauritius', code: 'MU', vatPrefix: null },
        { name: 'Mayotte', code: 'YT', vatPrefix: null },
        { name: 'Mexico', code: 'MX', vatPrefix: null },
        { name: 'Micronesia, Federated States of', code: 'FM', vatPrefix: null },
        { name: 'Moldova, Republic of', code: 'MD', vatPrefix: null },
        { name: 'Monaco', code: 'MC', vatPrefix: null },
        { name: 'Mongolia', code: 'MN', vatPrefix: null },
        { name: 'Montenegro', code: 'ME', vatPrefix: null },
        { name: 'Montserrat', code: 'MS', vatPrefix: null },
        { name: 'Morocco', code: 'MA', vatPrefix: null },
        { name: 'Mozambique', code: 'MZ', vatPrefix: null },
        { name: 'Myanmar', code: 'MM', vatPrefix: null },
        { name: 'Namibia', code: 'NA', vatPrefix: null },
        { name: 'Nauru', code: 'NR', vatPrefix: null },
        { name: 'Nepal', code: 'NP', vatPrefix: null },
        { name: 'Netherlands', code: 'NL', vatPrefix: 'NL' },
        { name: 'Netherlands Antilles', code: 'AN', vatPrefix: null },
        { name: 'New Caledonia', code: 'NC', vatPrefix: null },
        { name: 'New Zealand', code: 'NZ', vatPrefix: null },
        { name: 'Nicaragua', code: 'NI', vatPrefix: null },
        { name: 'Niger', code: 'NE', vatPrefix: null },
        { name: 'Nigeria', code: 'NG', vatPrefix: null },
        { name: 'Niue', code: 'NU', vatPrefix: null },
        { name: 'Norfolk Island', code: 'NF', vatPrefix: null },
        { name: 'North Macedonia', code: 'MK', vatPrefix: null },
        { name: 'Northern Mariana Islands', code: 'MP', vatPrefix: null },
        { name: 'Norway', code: 'NO', vatPrefix: null },
        { name: 'Oman', code: 'OM', vatPrefix: null },
        { name: 'Pakistan', code: 'PK', vatPrefix: null },
        { name: 'Palau', code: 'PW', vatPrefix: null },
        { name: 'Palestinian Territory, Occupied', code: 'PS', vatPrefix: null },
        { name: 'Panama', code: 'PA', vatPrefix: null },
        { name: 'Papua New Guinea', code: 'PG', vatPrefix: null },
        { name: 'Paraguay', code: 'PY', vatPrefix: null },
        { name: 'Peru', code: 'PE', vatPrefix: null },
        { name: 'Philippines', code: 'PH', vatPrefix: null },
        { name: 'Pitcairn', code: 'PN', vatPrefix: null },
        { name: 'Poland', code: 'PL', vatPrefix: 'PL' },
        { name: 'Portugal', code: 'PT', vatPrefix: 'PT' },
        { name: 'Puerto Rico', code: 'PR', vatPrefix: null },
        { name: 'Qatar', code: 'QA', vatPrefix: null },
        { name: 'Reunion', code: 'RE', vatPrefix: null },
        { name: 'Romania', code: 'RO', vatPrefix: 'RO' },
        { name: 'Russian Federation', code: 'RU', vatPrefix: null },
        { name: 'RWANDA', code: 'RW', vatPrefix: null },
        { name: 'Saint Helena', code: 'SH', vatPrefix: null },
        { name: 'Saint Kitts and Nevis', code: 'KN', vatPrefix: null },
        { name: 'Saint Lucia', code: 'LC', vatPrefix: null },
        { name: 'Saint Pierre and Miquelon', code: 'PM', vatPrefix: null },
        { name: 'Saint Vincent and the Grenadines', code: 'VC', vatPrefix: null },
        { name: 'Samoa', code: 'WS', vatPrefix: null },
        { name: 'San Marino', code: 'SM', vatPrefix: null },
        { name: 'Sao Tome and Principe', code: 'ST', vatPrefix: null },
        { name: 'Saudi Arabia', code: 'SA', vatPrefix: null },
        { name: 'Senegal', code: 'SN', vatPrefix: null },
        { name: 'Serbia', code: 'RS', vatPrefix: null },
        { name: 'Seychelles', code: 'SC', vatPrefix: null },
        { name: 'Sierra Leone', code: 'SL', vatPrefix: null },
        { name: 'Singapore', code: 'SG', vatPrefix: null },
        { name: 'Slovakia', code: 'SK', vatPrefix: 'SK' },
        { name: 'Slovenia', code: 'SI', vatPrefix: 'SI' },
        { name: 'Solomon Islands', code: 'SB', vatPrefix: null },
        { name: 'Somalia', code: 'SO', vatPrefix: null },
        { name: 'South Africa', code: 'ZA', vatPrefix: null },
        { name: 'South Georgia and the South Sandwich Islands', code: 'GS', vatPrefix: null },
        { name: 'Spain', code: 'ES', vatPrefix: 'ES' },
        { name: 'Sri Lanka', code: 'LK', vatPrefix: null },
        { name: 'Sudan', code: 'SD', vatPrefix: null },
        { name: 'Suriname', code: 'SR', vatPrefix: null },
        { name: 'Svalbard and Jan Mayen', code: 'SJ', vatPrefix: null },
        { name: 'Swaziland', code: 'SZ', vatPrefix: null },
        { name: 'Sweden', code: 'SE', vatPrefix: 'SE' },
        { name: 'Switzerland', code: 'CH', vatPrefix: null },
        { name: 'Syrian Arab Republic', code: 'SY', vatPrefix: null },
        { name: 'Taiwan, Province of China', code: 'TW', vatPrefix: null },
        { name: 'Tajikistan', code: 'TJ', vatPrefix: null },
        { name: 'Tanzania, United Republic of', code: 'TZ', vatPrefix: null },
        { name: 'Thailand', code: 'TH', vatPrefix: null },
        { name: 'Timor-Leste', code: 'TL', vatPrefix: null },
        { name: 'Togo', code: 'TG', vatPrefix: null },
        { name: 'Tokelau', code: 'TK', vatPrefix: null },
        { name: 'Tonga', code: 'TO', vatPrefix: null },
        { name: 'Trinidad and Tobago', code: 'TT', vatPrefix: null },
        { name: 'Tunisia', code: 'TN', vatPrefix: null },
        { name: 'Turkey', code: 'TR', vatPrefix: null },
        { name: 'Turkmenistan', code: 'TM', vatPrefix: null },
        { name: 'Turks and Caicos Islands', code: 'TC', vatPrefix: null },
        { name: 'Tuvalu', code: 'TV', vatPrefix: null },
        { name: 'Uganda', code: 'UG', vatPrefix: null },
        { name: 'Ukraine', code: 'UA', vatPrefix: null },
        { name: 'United Arab Emirates', code: 'AE', vatPrefix: null },
        { name: 'United Kingdom', code: 'GB', vatPrefix: 'GB' },
        { name: 'United States', code: 'US', vatPrefix: null },
        { name: 'United States Minor Outlying Islands', code: 'UM', vatPrefix: null },
        { name: 'Uruguay', code: 'UY', vatPrefix: null },
        { name: 'Uzbekistan', code: 'UZ', vatPrefix: null },
        { name: 'Vanuatu', code: 'VU', vatPrefix: null },
        { name: 'Venezuela', code: 'VE', vatPrefix: null },
        { name: 'Viet Nam', code: 'VN', vatPrefix: null },
        { name: 'Virgin Islands, British', code: 'VG', vatPrefix: null },
        { name: 'Virgin Islands, U.S.', code: 'VI', vatPrefix: null },
        { name: 'Wallis and Futuna', code: 'WF', vatPrefix: null },
        { name: 'Western Sahara', code: 'EH', vatPrefix: null },
        { name: 'Yemen', code: 'YE', vatPrefix: null },
        { name: 'Zambia', code: 'ZM', vatPrefix: null },
        { name: 'Zimbabwe', code: 'ZW', vatPrefix: null }
    ],
    languages: [
        {
            code: 'aa',
            name: 'Afar',
            native: 'Afar'
        },
        {
            code: 'ab',
            name: 'Abkhazian',
            native: 'Аҧсуа'
        },
        {
            code: 'af',
            name: 'Afrikaans',
            native: 'Afrikaans'
        },
        {
            code: 'ak',
            name: 'Akan',
            native: 'Akana'
        },
        {
            code: 'am',
            name: 'Amharic',
            native: 'አማርኛ'
        },
        {
            code: 'an',
            name: 'Aragonese',
            native: 'Aragonés'
        },
        {
            code: 'ar',
            name: 'Arabic',
            native: 'العربية',
            rtl: true
        },
        {
            code: 'as',
            name: 'Assamese',
            native: 'অসমীয়া'
        },
        {
            code: 'av',
            name: 'Avar',
            native: 'Авар'
        },
        {
            code: 'ay',
            name: 'Aymara',
            native: 'Aymar'
        },
        {
            code: 'az',
            name: 'Azerbaijani',
            native: 'Azərbaycanca / آذربايجان'
        },
        {
            code: 'ba',
            name: 'Bashkir',
            native: 'Башҡорт'
        },
        {
            code: 'be',
            name: 'Belarusian',
            native: 'Беларуская'
        },
        {
            code: 'bg',
            name: 'Bulgarian',
            native: 'Български'
        },
        {
            code: 'bh',
            name: 'Bihari',
            native: 'भोजपुरी'
        },
        {
            code: 'bi',
            name: 'Bislama',
            native: 'Bislama'
        },
        {
            code: 'bm',
            name: 'Bambara',
            native: 'Bamanankan'
        },
        {
            code: 'bn',
            name: 'Bengali',
            native: 'বাংলা'
        },
        {
            code: 'bo',
            name: 'Tibetan',
            native: 'བོད་ཡིག / Bod skad'
        },
        {
            code: 'br',
            name: 'Breton',
            native: 'Brezhoneg'
        },
        {
            code: 'bs',
            name: 'Bosnian',
            native: 'Bosanski'
        },
        {
            code: 'ca',
            name: 'Catalan',
            native: 'Català'
        },
        {
            code: 'ce',
            name: 'Chechen',
            native: 'Нохчийн'
        },
        {
            code: 'ch',
            name: 'Chamorro',
            native: 'Chamoru'
        },
        {
            code: 'co',
            name: 'Corsican',
            native: 'Corsu'
        },
        {
            code: 'cr',
            name: 'Cree',
            native: 'Nehiyaw'
        },
        {
            code: 'cs',
            name: 'Czech',
            native: 'Česky'
        },
        {
            code: 'cu',
            name: 'Old Church Slavonic / Old Bulgarian',
            native: 'словѣньскъ / slověnĭskŭ'
        },
        {
            code: 'cv',
            name: 'Chuvash',
            native: 'Чăваш'
        },
        {
            code: 'cy',
            name: 'Welsh',
            native: 'Cymraeg'
        },
        {
            code: 'da',
            name: 'Danish',
            native: 'Dansk'
        },
        {
            code: 'de',
            name: 'German',
            native: 'Deutsch'
        },
        {
            code: 'dv',
            name: 'Divehi',
            native: 'ދިވެހިބަސް',
            rtl: true
        },
        {
            code: 'dz',
            name: 'Dzongkha',
            native: 'ཇོང་ཁ'
        },
        {
            code: 'ee',
            name: 'Ewe',
            native: 'Ɛʋɛ'
        },
        {
            code: 'el',
            name: 'Greek',
            native: 'Ελληνικά'
        },
        {
            code: 'en',
            name: 'English',
            native: 'English'
        },
        {
            code: 'eo',
            name: 'Esperanto',
            native: 'Esperanto'
        },
        {
            code: 'es',
            name: 'Spanish',
            native: 'Español'
        },
        {
            code: 'et',
            name: 'Estonian',
            native: 'Eesti'
        },
        {
            code: 'eu',
            name: 'Basque',
            native: 'Euskara'
        },
        {
            code: 'fa',
            name: 'Persian',
            native: 'فارسی',
            rtl: true
        },
        {
            code: 'ff',
            name: 'Peul',
            native: 'Fulfulde'
        },
        {
            code: 'fi',
            name: 'Finnish',
            native: 'Suomi'
        },
        {
            code: 'fj',
            name: 'Fijian',
            native: 'Na Vosa Vakaviti'
        },
        {
            code: 'fo',
            name: 'Faroese',
            native: 'Føroyskt'
        },
        {
            code: 'fr',
            name: 'French',
            native: 'Français'
        },
        {
            code: 'fy',
            name: 'West Frisian',
            native: 'Frysk'
        },
        {
            code: 'ga',
            name: 'Irish',
            native: 'Gaeilge'
        },
        {
            code: 'gd',
            name: 'Scottish Gaelic',
            native: 'Gàidhlig'
        },
        {
            code: 'gl',
            name: 'Galician',
            native: 'Galego'
        },
        {
            code: 'gn',
            name: 'Guarani',
            native: `Avañe'ẽ`
        },
        {
            code: 'gu',
            name: 'Gujarati',
            native: 'ગુજરાતી'
        },
        {
            code: 'gv',
            name: 'Manx',
            native: 'Gaelg'
        },
        {
            code: 'ha',
            name: 'Hausa',
            native: 'هَوُسَ',
            rtl: true
        },
        {
            code: 'he',
            name: 'Hebrew',
            native: 'עברית',
            rtl: true
        },
        {
            code: 'hi',
            name: 'Hindi',
            native: 'हिन्दी'
        },
        {
            code: 'ho',
            name: 'Hiri Motu',
            native: 'Hiri Motu'
        },
        {
            code: 'hr',
            name: 'Croatian',
            native: 'Hrvatski'
        },
        {
            code: 'ht',
            name: 'Haitian',
            native: 'Krèyol ayisyen'
        },
        {
            code: 'hu',
            name: 'Hungarian',
            native: 'Magyar'
        },
        {
            code: 'hy',
            name: 'Armenian',
            native: 'Հայերեն'
        },
        {
            code: 'hz',
            name: 'Herero',
            native: 'Otsiherero'
        },
        {
            code: 'ia',
            name: 'Interlingua',
            native: 'Interlingua'
        },
        {
            code: 'id',
            name: 'Indonesian',
            native: 'Bahasa Indonesia'
        },
        {
            code: 'ie',
            name: 'Interlingue',
            native: 'Interlingue'
        },
        {
            code: 'ig',
            name: 'Igbo',
            native: 'Igbo'
        },
        {
            code: 'ii',
            name: 'Sichuan Yi',
            native: 'ꆇꉙ / 四川彝语'
        },
        {
            code: 'ik',
            name: 'Inupiak',
            native: 'Iñupiak'
        },
        {
            code: 'io',
            name: 'Ido',
            native: 'Ido'
        },
        {
            code: 'is',
            name: 'Icelandic',
            native: 'Íslenska'
        },
        {
            code: 'it',
            name: 'Italian',
            native: 'Italiano'
        },
        {
            code: 'iu',
            name: 'Inuktitut',
            native: 'ᐃᓄᒃᑎᑐᑦ'
        },
        {
            code: 'ja',
            name: 'Japanese',
            native: '日本語'
        },
        {
            code: 'jv',
            name: 'Javanese',
            native: 'Basa Jawa'
        },
        {
            code: 'ka',
            name: 'Georgian',
            native: 'ქართული'
        },
        {
            code: 'kg',
            name: 'Kongo',
            native: 'KiKongo'
        },
        {
            code: 'ki',
            name: 'Kikuyu',
            native: 'Gĩkũyũ'
        },
        {
            code: 'kj',
            name: 'Kuanyama',
            native: 'Kuanyama'
        },
        {
            code: 'kk',
            name: 'Kazakh',
            native: 'Қазақша'
        },
        {
            code: 'kl',
            name: 'Greenlandic',
            native: 'Kalaallisut'
        },
        {
            code: 'km',
            name: 'Cambodian',
            native: 'ភាសាខ្មែរ'
        },
        {
            code: 'kn',
            name: 'Kannada',
            native: 'ಕನ್ನಡ'
        },
        {
            code: 'ko',
            name: 'Korean',
            native: '한국어'
        },
        {
            code: 'kr',
            name: 'Kanuri',
            native: 'Kanuri'
        },
        {
            code: 'ks',
            name: 'Kashmiri',
            native: 'कश्मीरी / كشميري',
            rtl: true
        },
        {
            code: 'ku',
            name: 'Kurdish',
            native: 'Kurdî / كوردی',
            rtl: true
        },
        {
            code: 'kv',
            name: 'Komi',
            native: 'Коми'
        },
        {
            code: 'kw',
            name: 'Cornish',
            native: 'Kernewek'
        },
        {
            code: 'ky',
            name: 'Kirghiz',
            native: 'Kırgızca / Кыргызча'
        },
        {
            code: 'la',
            name: 'Latin',
            native: 'Latina'
        },
        {
            code: 'lb',
            name: 'Luxembourgish',
            native: 'Lëtzebuergesch'
        },
        {
            code: 'lg',
            name: 'Ganda',
            native: 'Luganda'
        },
        {
            code: 'li',
            name: 'Limburgian',
            native: 'Limburgs'
        },
        {
            code: 'ln',
            name: 'Lingala',
            native: 'Lingála'
        },
        {
            code: 'lo',
            name: 'Laotian',
            native: 'ລາວ / Pha xa lao'
        },
        {
            code: 'lt',
            name: 'Lithuanian',
            native: 'Lietuvių'
        },
        {
            code: 'lu',
            name: 'Luba-Katanga',
            native: 'Tshiluba'
        },
        {
            code: 'lv',
            name: 'Latvian',
            native: 'Latviešu'
        },
        {
            code: 'mg',
            name: 'Malagasy',
            native: 'Malagasy'
        },
        {
            code: 'mh',
            name: 'Marshallese',
            native: 'Kajin Majel / Ebon'
        },
        {
            code: 'mi',
            name: 'Maori',
            native: 'Māori'
        },
        {
            code: 'mk',
            name: 'Macedonian',
            native: 'Македонски'
        },
        {
            code: 'ml',
            name: 'Malayalam',
            native: 'മലയാളം'
        },
        {
            code: 'mn',
            name: 'Mongolian',
            native: 'Монгол'
        },
        {
            code: 'mo',
            name: 'Moldovan',
            native: 'Moldovenească'
        },
        {
            code: 'mr',
            name: 'Marathi',
            native: 'मराठी'
        },
        {
            code: 'ms',
            name: 'Malay',
            native: 'Bahasa Melayu'
        },
        {
            code: 'mt',
            name: 'Maltese',
            native: 'bil-Malti'
        },
        {
            code: 'my',
            name: 'Burmese',
            native: 'မြန်မာစာ'
        },
        {
            code: 'na',
            name: 'Nauruan',
            native: 'Dorerin Naoero'
        },
        {
            code: 'nb',
            name: 'Norwegian Bokmål',
            native: 'Norsk bokmål'
        },
        {
            code: 'nd',
            name: 'North Ndebele',
            native: 'Sindebele'
        },
        {
            code: 'ne',
            name: 'Nepali',
            native: 'नेपाली'
        },
        {
            code: 'ng',
            name: 'Ndonga',
            native: 'Oshiwambo'
        },
        {
            code: 'nl',
            name: 'Dutch',
            native: 'Nederlands'
        },
        {
            code: 'nn',
            name: 'Norwegian Nynorsk',
            native: 'Norsk nynorsk'
        },
        {
            code: 'no',
            name: 'Norwegian',
            native: 'Norsk'
        },
        {
            code: 'nr',
            name: 'South Ndebele',
            native: 'isiNdebele'
        },
        {
            code: 'nv',
            name: 'Navajo',
            native: 'Diné bizaad'
        },
        {
            code: 'ny',
            name: 'Chichewa',
            native: 'Chi-Chewa'
        },
        {
            code: 'oc',
            name: 'Occitan',
            native: 'Occitan'
        },
        {
            code: 'oj',
            name: 'Ojibwa',
            native: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin'
        },
        {
            code: 'om',
            name: 'Oromo',
            native: 'Oromoo'
        },
        {
            code: 'or',
            name: 'Oriya',
            native: 'ଓଡ଼ିଆ'
        },
        {
            code: 'os',
            name: 'Ossetian / Ossetic',
            native: 'Иронау'
        },
        {
            code: 'pa',
            name: 'Panjabi / Punjabi',
            native: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي'
        },
        {
            code: 'pi',
            name: 'Pali',
            native: 'Pāli / पाऴि'
        },
        {
            code: 'pl',
            name: 'Polish',
            native: 'Polski'
        },
        {
            code: 'ps',
            name: 'Pashto',
            native: 'پښتو',
            rtl: true
        },
        {
            code: 'pt',
            name: 'Portuguese',
            native: 'Português'
        },
        {
            code: 'qu',
            name: 'Quechua',
            native: 'Runa Simi'
        },
        {
            code: 'rm',
            name: 'Raeto Romance',
            native: 'Rumantsch'
        },
        {
            code: 'rn',
            name: 'Kirundi',
            native: 'Kirundi'
        },
        {
            code: 'ro',
            name: 'Romanian',
            native: 'Română'
        },
        {
            code: 'ru',
            name: 'Russian',
            native: 'Русский'
        },
        {
            code: 'rw',
            name: 'Rwandi',
            native: 'Kinyarwandi'
        },
        {
            code: 'sa',
            name: 'Sanskrit',
            native: 'संस्कृतम्'
        },
        {
            code: 'sc',
            name: 'Sardinian',
            native: 'Sardu'
        },
        {
            code: 'sd',
            name: 'Sindhi',
            native: 'सिनधि'
        },
        {
            code: 'se',
            name: 'Northern Sami',
            native: 'Sámegiella'
        },
        {
            code: 'sg',
            name: 'Sango',
            native: 'Sängö'
        },
        {
            code: 'sh',
            name: 'Serbo-Croatian',
            native: 'Srpskohrvatski / Српскохрватски'
        },
        {
            code: 'si',
            name: 'Sinhalese',
            native: 'සිංහල'
        },
        {
            code: 'sk',
            name: 'Slovak',
            native: 'Slovenčina'
        },
        {
            code: 'sl',
            name: 'Slovenian',
            native: 'Slovenščina'
        },
        {
            code: 'sm',
            name: 'Samoan',
            native: 'Gagana Samoa'
        },
        {
            code: 'sn',
            name: 'Shona',
            native: 'chiShona'
        },
        {
            code: 'so',
            name: 'Somalia',
            native: 'Soomaaliga'
        },
        {
            code: 'sq',
            name: 'Albanian',
            native: 'Shqip'
        },
        {
            code: 'sr',
            name: 'Serbian',
            native: 'Српски'
        },
        {
            code: 'ss',
            name: 'Swati',
            native: 'SiSwati'
        },
        {
            code: 'st',
            name: 'Southern Sotho',
            native: 'Sesotho'
        },
        {
            code: 'su',
            name: 'Sundanese',
            native: 'Basa Sunda'
        },
        {
            code: 'sv',
            name: 'Swedish',
            native: 'Svenska'
        },
        {
            code: 'sw',
            name: 'Swahili',
            native: 'Kiswahili'
        },
        {
            code: 'ta',
            name: 'Tamil',
            native: 'தமிழ்'
        },
        {
            code: 'te',
            name: 'Telugu',
            native: 'తెలుగు'
        },
        {
            code: 'tg',
            name: 'Tajik',
            native: 'Тоҷикӣ'
        },
        {
            code: 'th',
            name: 'Thai',
            native: 'ไทย / Phasa Thai'
        },
        {
            code: 'ti',
            name: 'Tigrinya',
            native: 'ትግርኛ'
        },
        {
            code: 'tk',
            name: 'Turkmen',
            native: 'Туркмен / تركمن'
        },
        {
            code: 'tl',
            name: 'Tagalog / Filipino',
            native: 'Tagalog'
        },
        {
            code: 'tn',
            name: 'Tswana',
            native: 'Setswana'
        },
        {
            code: 'to',
            name: 'Tonga',
            native: 'Lea Faka-Tonga'
        },
        {
            code: 'tr',
            name: 'Turkish',
            native: 'Türkçe'
        },
        {
            code: 'ts',
            name: 'Tsonga',
            native: 'Xitsonga'
        },
        {
            code: 'tt',
            name: 'Tatar',
            native: 'Tatarça'
        },
        {
            code: 'tw',
            name: 'Twi',
            native: 'Twi'
        },
        {
            code: 'ty',
            name: 'Tahitian',
            native: 'Reo Mā`ohi'
        },
        {
            code: 'ug',
            name: 'Uyghur',
            native: 'Uyƣurqə / ئۇيغۇرچە'
        },
        {
            code: 'uk',
            name: 'Ukrainian',
            native: 'Українська'
        },
        {
            code: 'ur',
            name: 'Urdu',
            native: 'اردو',
            rtl: true
        },
        {
            code: 'uz',
            name: 'Uzbek',
            native: 'Ўзбек'
        },
        {
            code: 've',
            name: 'Venda',
            native: 'Tshivenḓa'
        },
        {
            code: 'vi',
            name: 'Vietnamese',
            native: 'Tiếng Việt'
        },
        {
            code: 'vo',
            name: 'Volapük',
            native: 'Volapük'
        },
        {
            code: 'wa',
            name: 'Walloon',
            native: 'Walon'
        },
        {
            code: 'wo',
            name: 'Wolof',
            native: 'Wollof'
        },
        {
            code: 'xh',
            name: 'Xhosa',
            native: 'isiXhosa'
        },
        {
            code: 'yi',
            name: 'Yiddish',
            native: 'ייִדיש',
            rtl: true
        },
        {
            code: 'yo',
            name: 'Yoruba',
            native: 'Yorùbá'
        },
        {
            code: 'za',
            name: 'Zhuang',
            native: 'Cuengh / Tôô / 壮语'
        },
        {
            code: 'zh',
            name: 'Chinese',
            native: '中文'
        },
        {
            code: 'zu',
            name: 'Zulu',
            native: 'isiZulu'
        }
    ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export class Address {
    public country: string;
    public state?: string;
    public city: string;
    public street: string;
    public street_number?: string; // not used atm
    public street_formatted?: string;
    public postal_code: string;

    public longitude?: number;
    public latitude?: number;

    public is_city?: boolean;
    public utc_offset?: number;
    public url?: string;
    public google_place_id?: string;
    public types?: string;


    constructor() {
        this.country = null;
        this.state = null;
        this.city = null;
        this.street = null;
        this.street_number = null;
        this.street_formatted = null;
        this.postal_code = null;
        this.is_city = null;
        this.utc_offset = null;
        this.url = null;
        this.google_place_id = null;
        this.types = null;
    }
}

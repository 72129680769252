import { Customer } from './Customer';
import { Address } from './Address';
import { ViesData } from './VIES';

export class RegistrationData {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    password?: string;
    password_confirm?: string;
    language?: 'el' | 'en';
    address?: Address;
    type?: 'individual' | 'business';
    business_name?: string;
    business_email?: string;
    business_phone?: string;
    business_title?: string;
    tax_id?: string;
    tax_authority?: string;
    vies_data?: ViesData;
    birth_date: Date;
    identity_type?: 'passport' | 'driving_license' | 'identity_card';
    identity_card_number?: string;
    passport_number?: string;
    driving_license_number?: string;


    constructor() {
        this.first_name = null;
        this.last_name = null;
        this.email = null;
        this.phone = null;
        this.password = null;
        this.password_confirm = null;
        this.language = null;
        this.type = 'individual';
        this.tax_id = null;
        this.tax_authority = null;
        this.business_name = null;
        this.business_title = null;
        this.business_email = null;
        this.business_phone = null;
        this.identity_card_number = null;
        this.passport_number = null;
        this.driving_license_number = null;
        this.birth_date = null;
        this.address = {

            street: null,
            street_number: null,
            postal_code: null,
            city: null,
            state: null,
            country: null,
            is_city: null,
            utc_offset: null,
            url: null,
            google_place_id: null,
            types: null,
            longitude: null,
            latitude: null,
        };
    }
}

import { StorageService } from '../storage/storage.service';
import { UtilsService } from './utils.service';
import { Router } from '@angular/router';

import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

import { Observable, EMPTY } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    private totalRequests = 0;

    public appLanguage: 'en' | 'el' = null;

    constructor(
        private router: Router,
        private utilsService: UtilsService,
        private storageService: StorageService,
        private pubSub: NgxPubSubService,
        @Inject(PLATFORM_ID) private platformId: any,
        @Optional() @Inject(REQUEST) protected serverRequest?: Request
        // private spinnerService: SpinnerService
    ) {
        const language = this.storageService.getItem('appLanguage');
        this.appLanguage = language ? language : 'en';


        this.pubSub.subscribe('changeLanguage', (lang) => { if (lang) this.appLanguage = lang; });

    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        // tslint:disable-next-line:curly
        if (isPlatformServer(this.platformId)) {
            if (req.method === 'GET') {
                const requestUrl = req.url.replace(/^\./, '');
                if (this.serverRequest && !(requestUrl.startsWith('http') || requestUrl.startsWith('//'))) {
                    const protocolHost = `${this.serverRequest.protocol}://${this.serverRequest.get('host')}`;
                    const pathSeparator = !requestUrl.startsWith('/') ? '/' : '';
                    const url = protocolHost + pathSeparator + requestUrl;
                    req = req.clone({ url });
                }

                return next.handle(req);

            }
            else
                return EMPTY; // return all call except GET method

        }

        else {

            // if (this.totalRequests === 0)
            // this.spinnerService.setHttpProgressStatus(true);

            this.totalRequests++;



            // console.log("interceptor: " + req.url);
            req = req.clone({
                withCredentials: true,
                setHeaders: {
                    'X-Timezone-Name': moment.tz.guess() || null,
                    'X-Language': this.appLanguage
                    // currency: 'eur',
                }
            });

            // return next.handle(req);

            return next.handle(req).pipe(
                tap(() => { }, (error: any) => {

                    this.totalRequests--;

                    // if (this.totalRequests === 0)
                    // this.spinnerService.setHttpProgressStatus(false);

                    if (error instanceof HttpErrorResponse) {

                        if (error.status === 0)
                            return;

                        if (error.status !== 401)
                            if (this.totalRequests < 1) {
                                if (error && error.error && error.error.message)
                                    this.utilsService.showToast(`${error && error.error && error.error.message}`, null, 'error', 3500);
                                else
                                    this.utilsService.showToast('Something went wrong, please try again later.', null, 'error', 3500);
                                return;
                            }
                            else
                                return;


                        this.storageService.removeItem('customer');

                        this.router.navigate(['/']);
                    }
                }, () => {
                    this.totalRequests--;

                    // if (this.totalRequests === 0)
                    // this.spinnerService.setHttpProgressStatus(false);
                }));

        }


    }
}

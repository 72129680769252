import { LayoutModule } from './components/layout/layouts.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserModule, BrowserTransferStateModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';


import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
// import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { LaddaModule } from 'angular2-ladda';
import { NgpSortModule } from 'ngp-sort-pipe';
import { HttpRequestInterceptor } from './services/utils/http-request-interceptor.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

import { TransferHttpCacheModule } from '@nguniversal/common';
import { PipesModule } from './pipes/pipes.module';


// AoT requires an exported function for factories
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', `.json?av=${environment.params.appVersion}`); // av = appversion, e.g. 2.72.7
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule.withServerTransition({ appId: 'tourmie-stay' }),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    LayoutModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ComponentsModule,
    PipesModule,
    NgxPubSubModule,
    ShareButtonsModule,
    ShareIconsModule,
    RouterModule,
    HammerModule,
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    LaddaModule,
    NgpSortModule,
    FullCalendarModule,
    FontAwesomeModule,
    NgxYoutubePlayerModule.forRoot(),
    AngularFireModule.initializeApp(environment.params.firebase),
    AngularFireMessagingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (TranslateHttpLoaderFactory),
        deps: [HttpClient],
      },
      defaultLanguage: 'en'
    }),
    // ServiceWorkerModule.register('ngsw-worker.js'),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    // { provide: SwRegistrationOptions, useFactory: () => ({ enabled: environment.production, registrationStrategy: 'registerImmediately' }) },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { BookingSubmissionPageComponent } from './components/booking-submission-page/booking-submission-page.component';
import { LogoutComponent } from './views/logout/logout.component';
import { AuthGuard } from './services/utils/auth-guard.service';
import { MainLayoutComponent } from './components/layout/main-layout/main-layout.component';
import { HomePageModule } from './views/home/home.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageModule } from './views/login/login.module';
import { RegistrationPageModule } from './views/registration/registration.module';
import { CustomerDetailsModule } from './views/customer/customer-details/customer-details.module';
import { BookingDetailsModule } from './views/booking-details/booking-details.module';
import { Page404Module } from './views/page404/page404.module';
import { EmailVerificationModule } from './views/email-verification/email-verification.module';
import { ResetPasswordModule } from './views/reset-password/reset-password.module';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => HomePageModule,
      },
    ]
  },

  {
    path: 'listyourproperty',
    component: MainLayoutComponent,
    loadChildren: () => import('./views/listyourproperty/listyourproperty.module').then(m => m.ListYourPropertyPageModule)
  },
  {
    path: 'properties',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/properties/properties-list/properties-list.module').then(m => m.AccommodationsListModule),
      },
      {
        path: ':property_id',
        loadChildren: () => import('./views/properties/property-details/property-details.module').then(m => m.AccommodationDetailsModule),
      },
    ]
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    // loadChildren: () => CustomerDetailsModule
    loadChildren: () => import('./views/customer/customer-details/customer-details.module').then(m => m.CustomerDetailsModule)
  },
  {
    path: 'bookings',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    // loadChildren: () => CustomerDetailsModule
    loadChildren: () => import('./views/customer/customer-details/customer-details.module').then(m => m.CustomerDetailsModule)
  },
  {
    path: 'bookings/:booking_id',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    // loadChildren: () => BookingDetailsModule
    loadChildren: () => import('./views/booking-details/booking-details.module').then(m => m.BookingDetailsModule)
  },
  {
    path: 'favorites',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => CustomerDetailsModule
  },
  {
    path: 'reviews',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => CustomerDetailsModule
  },
  // {
  //   path: 'about',
  //   component: MainLayoutComponent,
  //   loadChildren: () => import('./views/about/about.module').then(m => m.AboutPageModule)
  // },
  {
    path: 'booking-confirmation/:booking_id',
    component: BookingSubmissionPageComponent
  },
  // {
  //   path: 'agents',
  //   component: MainLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => AgentListModule
  //     },
  //     {
  //       path: ':agent_id',
  //       loadChildren: () => AgentDetailsModule
  //     }
  //   ]
  // },



  // Auth
  {
    path: 'login',
    loadChildren: () => LoginPageModule
  },
  {
    path: 'registration',
    loadChildren: () => RegistrationPageModule
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    component: LogoutComponent
  },
  {
    path: 'email-verification',
    loadChildren: () => EmailVerificationModule
  },
  {
    path: 'password-reset',
    loadChildren: () => ResetPasswordModule
  },



  // Other pages
  {
    path: 'lease-agreement/:booking_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/lease-agreement/lease-agreement.module').then(m => m.LeaseAgreementModule)
  },
  {
    path: 'faq',
    component: MainLayoutComponent,
    loadChildren: () => import('./views/faq/categories/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'faq/:category/:child_category_slug/:post_slug',
    component: MainLayoutComponent,
    loadChildren: () => import('./views/faq/article/faq-article.module').then(m => m.FaqArticleModule)
  },
  {
    path: 'contact',
    component: MainLayoutComponent,
    loadChildren: () => import('./views/contact-page/contact-page.module').then(m => m.ContactPageModule)
  },
  {
    path: 'demo-request',
    component: MainLayoutComponent,
    loadChildren: () => import('./views/demo-request/demo-request.module').then(m => m.DemoRequestModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./views/maintentance-page/maintentance-page.module').then(m => m.MaintentancePageModule)
  },
  {
    path: 'privacy-policy',
    component: MainLayoutComponent,
    loadChildren: () => import('./views/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'terms-of-service',
    component: MainLayoutComponent,
    loadChildren: () => import('./views/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule)
  },
  {
    path: '404',
    component: MainLayoutComponent,
    loadChildren: () => Page404Module
  },
  { path: '**', component: MainLayoutComponent, loadChildren: () => Page404Module }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    // scrollOffset: [0, 60],
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { PipesModule } from './../../pipes/pipes.module';
import { ComponentsModule } from '../../components/components.module';
import { NgModule } from '@angular/core';
import { LaddaModule } from 'angular2-ladda';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [RegistrationComponent],
  imports: [
    CommonModule,
    FormsModule,
    LaddaModule,
    NgSelectModule,
    RegistrationRoutingModule,
    TranslateModule,
    ComponentsModule,
    NgxIntlTelInputModule,
    TooltipModule,
    PipesModule,
  ]
})
export class RegistrationPageModule { }

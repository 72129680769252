import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild, TemplateRef, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { UtilsService } from '../../services/utils/utils.service';
import { StorageService } from '../../services/storage/storage.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';

interface TenantContactForm {
  full_name: string;
  email?: string;
  phone: string;
}

@Component({
  selector: 'app-tenant-promo-modal',
  templateUrl: './tenant-promo-modal.component.html',
  styleUrls: ['./tenant-promo-modal.component.scss']
})
export class TenantPromoModalComponent implements OnInit, OnDestroy {

  @ViewChild('tenantPromoModal', { static: false }) tenantPromoModal: TemplateRef<any>;

  @Input() type: string;

  public tenantModalRef: BsModalRef;
  public isMobile = false;
  private isMobileSubscription: Subscription;
  public submittingTenantForm: boolean;
  public tenantFormData: TenantContactForm;
  public countries = environment.countries;
  public tenantPhone;
  public SearchCountryField = SearchCountryField;
  public TooltipLabel = TooltipLabel;
  public CountryISO = CountryISO;
  public preferredCountries: CountryISO[] = [CountryISO.Greece];

  constructor(
    public bsModalRef: BsModalRef,
    private http: HttpClient,
    public utilsService: UtilsService) {

    this.isMobile = this.utilsService.isMobile;

  }
  // private modalService: BsModalService

  ngOnInit(): void {
    this.tenantFormData = {
      full_name: null,
      email: null,
      phone: null
    };
    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    console.log(this.type);
  }

  ngOnDestroy() {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();
  }

  updateTenantMobileNumber(): void {
    if (this.tenantPhone?.internationalNumber)
      this.tenantFormData.phone = this.tenantPhone.internationalNumber;
    else
      this.tenantFormData.phone = null;
  }

  async submitTenantForm() {
    this.submittingTenantForm = true;
    try {

      const result = await this.http.post(`${environment.params.host}/api/stay/utils/`, this.tenantFormData).toPromise();

    } catch (error) {
      if (!environment.production)
        console.log(error);
    }
    this.submittingTenantForm = false;
    this.bsModalRef.hide();
  }

}
